import {
  BackgroundColor,
  BorderRadius,
  Button,
  ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  Height,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Margin,
  MaxWidth,
  Padding,
  TextAlign,
  TextColor,
  Width
} from '@snoam/pinata';
import * as React from 'react';
import {useContext, useState} from 'react';
import {ADD_ADMIN, checkNoErrors} from "../../mutations";
import {ApolloError, MutationFunction, PureQueryOptions, useMutation} from "@apollo/client";
import {AddAdmin, AddAdminVariables} from "../../__generated__/AddAdmin";
import {GET_AGREEMENT} from "../../queries";
import {AdminType} from "../../__generated__/globalTypes";
import {GetAgreementVariables} from "../../__generated__/GetAgreement";
import {DepartmentContext} from "../../context/DepartmentContext/DepartmentContext";

const styleClass = {
  button: classNames(
    MaxWidth.MAX_W_XS,
    Width.W_FULL,
    Margin.MT_2,
    Display.HIDDEN,
    Display.MD_FLEX
  ),
  buttonIcon: classNames(
    Padding.P_1,
    TextColor.TEXT_WHITE,
    BorderRadius.ROUNDED_FULL,
    Height.H_4,
    Margin.ML_4,
    Margin.MT_1,
    BackgroundColor.BG_POSITIVE,
  ),
  errorText: classNames(TextColor.TEXT_NEGATIVE, TextAlign.TEXT_CENTER, FontSize.TEXT_SM, MaxWidth.MAX_W_XS)
};

interface IAdministratorAddButton {
  spid: number;
  agreementNumber: number;
  triggerTooltip: () => void;
}
const debug = require('debug')('minbedrift:client:Administrator:AddButton');

const AdministratorAddButton: React.FunctionComponent<IAdministratorAddButton> = ({spid, agreementNumber, triggerTooltip}) => {
  const addAdminMutation: MutationFunction<AddAdmin, AddAdminVariables> = useMutation<AddAdmin, AddAdminVariables>(ADD_ADMIN)[0];
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const activeDepartment = useContext(DepartmentContext);
  const refetchQueries: PureQueryOptions[] = [{
    query: GET_AGREEMENT,
    variables: ((): GetAgreementVariables => {
      return {
        agreementNumber: [agreementNumber],
        shouldFetchSubscriptions: true,
        department: activeDepartment ? activeDepartment : null
      };
    })(),
  }];

  const onAddAdminClick = () => {
    setError(null);
    setIsSaving(true);
    addAdminMutation({
      refetchQueries,
      variables: {
        admin: {
          spid: spid,
          adminType: AdminType.ADMIN,
          agreementNumber
        }
      }
    }).then((err) => {

      setIsSaving(false);
      setIsSaved(true);
      triggerTooltip();
      return err ? checkNoErrors(err) : Promise.reject(`Expected return value, got: ${err}`);
    }).catch((err) => {
      setIsSaving(false);
      setError("Noe gikk galt, prøv gjerne igjen senere.");
      if (err instanceof ApolloError) {
        debug(err.message.replace('GraphQL error: Unexpected error value: ', '').replace(/^"(.*)"$/, '$1'));
      } else {
        debug(err);
      }
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      e.preventDefault();
      onAddAdminClick();
    }
  };

  return (
    <div className={classNames(Display.FLEX, FlexDirection.FLEX_COL)}>
      <Button
        text={isSaved ? "Lagt til" : "Legg til som administrator"}
        ariaLabel={isSaved ? "Lagt til" : "Legg til som administrator"}
        skin={ButtonSkin.SECONDARY}
        onClick={onAddAdminClick}
        onKeyDown={handleKeyDown}
        loading={isSaving}
        disabled={isSaving}
        loadingText="Legger til..."
        className={classNames(Margin.MB_1, MaxWidth.MAX_W_XS, Width.W_FULL, Margin.MT_4)}
        iconRight={isSaved ? <Icon name={IconName.CHECK} color={IconColor.POSITIVE} size={IconSize.LARGE}/> : undefined}
        style={{transition: 'background-color 500ms ease'}}
        iconLeft={
          <Icon
            style={{width: '26px', height: '26px'}}
            name={IconName.BEDRIFT_ADMIN}
            className={styleClass.buttonIcon}
          />}
      />
      {error &&
      <div className={styleClass.errorText}>
        {error}
      </div>
      }
    </div>
  );
};

export default AdministratorAddButton;
