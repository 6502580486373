import {GetAgreements_me_agreements_edges} from "../../__generated__/GetAgreements";
import {
  BackgroundColor, BorderColor, BorderStyle, BorderWidth,
  classNames, Display,
  FontSize,
  Heading,
  HeadingLevel, Height,
  Input, JustifyContent, Margin, MinHeight,
  Padding,
  TextColor, TextDecoration,
  Width
} from "@snoam/pinata";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {capitalize} from "../../utils";

export interface IActivationDegreeWidget {
  filteredAgreements: GetAgreements_me_agreements_edges[],
  heading: string,
  defaultPotential?: number,
}

const styleClass = {
  root: classNames(
    BackgroundColor.BG_WHITE,
    Padding.P_4,
    MinHeight.MIN_H_XL,
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_3,
  ),
  label: classNames(
    FontSize.TEXT_SM,
    TextColor.TEXT_NEUTRAL_5,
  ),
  bar: {
    wrapper: classNames(
      Display.FLEX,
      JustifyContent.JUSTIFY_BETWEEN,
      Padding.PY_2,
    ),
    link: classNames(
      TextColor.HOVER_TEXT_PRIMARY_1,
      TextDecoration.HOVER_UNDERLINE,
      FontSize.TEXT_SM,
    ),
    col: classNames(
      Height.H_6,
      Padding.PR_2,
      Width.W_FULL,
    ),
    used: classNames(
      BackgroundColor.BG_PRIMARY_1,
      Display.INLINE_BLOCK,
      MinHeight.MIN_H_FULL
    ),
    remaining: classNames(
      BackgroundColor.BG_NEUTRAL_3,
      Display.INLINE_BLOCK,
      MinHeight.MIN_H_FULL
    ),
  }
}

const ActivationDegreeWidget: React.FunctionComponent<IActivationDegreeWidget> = (props) => {
  const {filteredAgreements, heading, defaultPotential = 50} = props;
  const [potential, setPotential] = useState<number>(defaultPotential);
  const [agreements, setAgreements] = useState<GetAgreements_me_agreements_edges[]>(filteredAgreements);

  useEffect(() => {
    setAgreements(filteredAgreements.filter(a => a.settings.activationPotential! >= potential))
  }, [potential]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setPotential(value);
  }

  const activationDegreeBar = (a: GetAgreements_me_agreements_edges) => {
    const name = a.accountName.length > 17 ? a.accountName.substr(0, 17) + "..." : a.accountName;
    const used = Math.min(a.activationDegree!, 100);
    const remaining = 100 - Math.min(a.activationDegree!, 100);
    const percentage = Math.round(Math.round((a.activationDegree! + Number.EPSILON) * 100) / 100);

    return (
      <div className={styleClass.bar.wrapper} key={`activation-degree-${a.id}`}>
        <div className={Width.W_48}>
          <Link className={styleClass.bar.link} to={`/admin/avtaler/${a.agreementNumber}/abonnenter`}>{capitalize(name)}</Link>
        </div>
        <div className={styleClass.bar.col}>
          <span className={styleClass.bar.used} style={{minWidth: `${used}%`}}/>
          <span className={styleClass.bar.remaining} style={{minWidth: `${remaining}%`}}/>
        </div>
        <div className={Width.W_12}>
          <span>{percentage}% </span>
        </div>
      </div>
    )
  }

  return (
    <div className={styleClass.root}>
      <div>
        <span className={styleClass.label}>TOPP 20</span>
        <Heading level={HeadingLevel.TWO}>{heading}</Heading>
      </div>
      <div>
        Vis avtaler med potensiale på minst
        <Input
          className={classNames(Width.W_14, Display.INLINE_BLOCK, Margin.MR_1)}
          value={potential}
          onChange={handleChange}
        />
        abonnement
      </div>
      <div className={classNames(Padding.PT_4)}>
        {agreements
          .slice(0, 20)
          .map(a => {
          return activationDegreeBar(a);
        })}
      </div>
    </div>
  )
}

export default ActivationDegreeWidget;
