import * as React from 'react';
import {createContext, useEffect, useState} from "react";
import {getMaintenanceUrl} from "../utils";
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Display,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  Padding
} from "@snoam/pinata";
const fetch = require('node-fetch');

interface IMaintenance {
  active: boolean;
  message: string;
  loading: boolean;
}

const MaintenanceContext = createContext({
  active: false,
  message: "",
  loading: true,
});

const styleClass = {
  maintenance: {
    root: classNames(
      BackgroundColor.BG_NEUTRAL_2,
      Padding.P_4
    ),
    headingRoot: classNames(
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
    ),
    heading: classNames(
      Padding.PY_2,
      Padding.PL_2,
    )
  }
}

const MaintenanceContextProvider: React.FC = ({children}) => {
  const [maintenance, setMaintenance] = useState<IMaintenance>({active: false, message: "", loading: true});

  useEffect(() => {
    getMaintenanceStatus();
  }, [])

  const getMaintenanceStatus = async () => {
    await fetch(getMaintenanceUrl())
      .then((res: Response) => res.json())
      .then((jsonRes: any) => setMaintenance({active: jsonRes.maintenenceActive, message: jsonRes.maintenenceText, loading: false}))
      .catch((e: any) => {
        setMaintenance({active: false, message: "", loading: false});
      });
  }

  return (
    <MaintenanceContext.Provider value={maintenance}>
      {!maintenance.loading && maintenance.active ?
        <div className={styleClass.maintenance.root}>
          <div className={styleClass.maintenance.headingRoot}>
            <Icon name={IconName.ERROR} color={IconColor.INFO}/>
            <Heading className={styleClass.maintenance.heading} title={"Straks tilbake..."} level={HeadingLevel.THREE}>Straks tilbake...</Heading>
          </div>
          <p>{maintenance && maintenance.message}</p>
        </div> : children
      }
    </MaintenanceContext.Provider>
  )
}

export default MaintenanceContextProvider;
