import * as React from "react";
import { MinBedriftRouteComponentProps } from "../../../routes/utils";
import {
  GetAgreementOrderInitial_agreement,
  GetAgreementOrderInitial_agreement_products
} from "../../../__generated__/GetAgreementOrderInitial";
import { useSpid } from "@snoam/mono-spid";
import { useState } from "react";
import { createOrder, RedeemInviteCodeReturn, ScoreIntegrationError } from "../../../utils";
import { OrderSessionModel } from "../models/OrderSessionModel";
import { useEffect } from "react";
import { IAddress } from "@snoam/mono-address";
import ErrorPage from "../../ErrorPage/ErrorPage";
import qs from "qs";
import SvgImage from "../../../components/SvgImage/SvgImage";
import { Width } from "@snoam/pinata";
import { LoadingFragment } from "../../LoadingPage";

const PlaceOrder: React.FunctionComponent<MinBedriftRouteComponentProps<{}> & { agreement: GetAgreementOrderInitial_agreement, className?: string, code: string; }> = ({ agreement, className, code, history }) => {
  const spidContext = useSpid();
  const [orderResult, setOrderResult] = useState<RedeemInviteCodeReturn | null>(null);
  const orderModel = new OrderSessionModel();
  const selectedProducts: GetAgreementOrderInitial_agreement_products[] = agreement
    .products
    .filter(p => orderModel.getProductById(p.id));
  const products = selectedProducts.reduce((o, p) => {
    return {
      ...o,
      [p.siebelProductId]: {
        companyCode: p.companyCode,
        productCombination: p.productCombination,
        productName: p.productName,
      },
    };
  }, {});
  const references = orderModel.referencesIndexByProductId();

  useEffect(() => {
    createOrder({
      address: orderModel.address as IAddress,
      agreementNumber: agreement.agreementNumber,
      openidConnectCode: code,
      products,
      references,
      selectedProducts,
      spidContext
    })
      .then((result) => {
        setOrderResult(result || {
          error: '',
          orderIds: [],
          products: [],
          user: {
            userId: ((spidContext && spidContext.state && spidContext.state.userId !== null) ? spidContext.state.userId : 0),
          },
        });
      })
      .catch((err) => {
        setOrderResult({
          error: (err instanceof ScoreIntegrationError) ? err.errorMessage as string || err.toString() : err.toString(),
          orderIds: [],
          products: [],
          user: {
            userId: ((spidContext && spidContext.state && spidContext.state.userId !== null) ? spidContext.state.userId : 0),
          },
        });
      });
  }, []);

  if (orderResult) {
    if (orderResult.error) {
      return (
        <ErrorPage
          heading={ 'Det oppstod en feil!' }
          message={ orderResult.error }
        />
      );
    } else {
      const urlParams = qs.stringify({
        givenName: orderResult.user.givenName || '',
        productGroups: orderResult.products.map(p => `${ p.companyCode }_${ p.productName.replace(/\W/g, '_') }`),
      });
      const redirectPath = (window.location.pathname).replace(/\/fra-ekstern-login/, `/kvittering?${ urlParams }`);
      history.push(redirectPath);
    }
  }

  return (
    <div className={ className }>
      <SvgImage href={ `${ process.env.PUBLIC_URL }/svg/email-sendt.svg` } title={ "Bestiller" }
                className={ Width.W_FULL }/>
      <LoadingFragment label={ "Bestiller" } style={ {} }/>
    </div>
  );
};

export default PlaceOrder;
