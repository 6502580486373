import {
  AlignItems,
  classNames,
  Display,
  Height,
  Icon,
  IconName,
  JustifyContent,
  Link,
  Padding,
  TextAlign,
  TextColor,
  TextDecoration,
  Width
} from "@snoam/pinata";
import * as React from "react";
import {chunk, clamp} from "../../utils";
import {ensureArray} from "../Filter/utils";


type PaginationFaaCProps = {
  chunk: any[];
  canEnablePagination: boolean;
  page: number;
};
type PaginationProviderProps = {
  pageSize: number;
  rows: any[];
  enablePagination: boolean;
  children: (props: PaginationFaaCProps) => JSX.Element;
}

type PaginationContextValue = any[];
const PaginationContext = React.createContext<PaginationContextValue>([0, () => void (0), 0]);
export const PaginationProvider: React.FunctionComponent<PaginationProviderProps> = ({children, pageSize, rows, enablePagination}) => {
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    setCurrentPage(0);
  }, [rows.length]);


  const chunked = chunk(rows, pageSize);
  const pageCount = chunked.length;
  const activeChunk = chunked[currentPage] || [];
  const canEnablePagination = enablePagination && rows.length > pageSize && activeChunk.length > 0;


  return (
    <PaginationContext.Provider value={[currentPage, setCurrentPage, pageCount]}>
      {
        children({
          chunk: ensureArray(enablePagination ? activeChunk : rows),
          canEnablePagination,
          page: currentPage
        })
      }
    </PaginationContext.Provider>
  )
};

const styleClass = {
  root: (className?: string) => classNames(
    Padding.PY_2,
    Display.INLINE_FLEX,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_END,
    className
  ),
  link: {
    root: (active: boolean) => classNames({
        [TextColor.TEXT_NEUTRAL_6]: active,
        [TextDecoration.NO_UNDERLINE]: active,
      }
    ),
    inner: classNames(
      Width.W_6,
      Height.H_6,
      TextAlign.TEXT_CENTER
    )
  }
};

type PaginationProps = {
  className?: string;
}

const PaginationLink: React.FunctionComponent<any> = props => {
  const {className = '', isFirst, isLast, ...otherProps} = props;
  return (
    <Link
      {...otherProps}
      className={classNames(
        {
          [Padding.PX_2]: !isFirst && !isLast,
          [Padding.PR_2]: isFirst && !isLast,
          [Padding.PL_2]: !isFirst && isLast,
        },
        className
      )}
    />
  );
};

export const Pagination: React.FunctionComponent<PaginationProps> = ({className}) => {

  const [currentPage, setCurrentPage, pageCount] = React.useContext(PaginationContext);
  const [currentChunk, setCurrentChunk] = React.useState<number[]>([]);

  const pageRangeDisplayed = 4;

  const pages = Array(pageCount).fill(0).map((_, i) => i);
  const lastPage = pages.length;
  const firstPage = 0;

  React.useEffect(() => {

    if (pageCount <= pageRangeDisplayed) {
      setCurrentChunk(pages);
    } else {
      let subset: number[] = [];
      if (currentPage - 2 <= firstPage) {
        subset = pages.slice(firstPage, pageRangeDisplayed);
      } else if (currentPage + 2 >= lastPage) {
        subset = pages.slice(lastPage - pageRangeDisplayed, lastPage);
      } else {
        subset = pages.slice(currentPage - 2, currentPage + 2);
      }
      setCurrentChunk(subset);
    }
  }, [currentPage]);

  return (
    <div className={styleClass.root(className)}>

      <PaginationLink
        onClick={() => {
          setCurrentPage(firstPage)
        }}
        isFirst={true}
      >
        <Icon name={IconName.ARROW_BACK} screenReaderText={'Gå til første side'} />
      </PaginationLink>

      <PaginationLink onClick={() => {
        setCurrentPage(clamp(currentPage - 1, firstPage, lastPage - 1))
      }}>
        <Icon name={IconName.KEYBOARD_ARROW_LEFT} screenReaderText={'Gå til forrige side'} />
      </PaginationLink>

      <>
        {
          currentChunk
            .map((page) => {
              return (
                <PaginationLink
                  key={`pagination_page_${page}_${currentPage === page}`}
                  className={styleClass.link.root(currentPage === page)}
                  onClick={() => {
                    setCurrentPage(clamp(page, firstPage, lastPage - 1))
                  }}>
                  <div className={styleClass.link.inner}>{page + 1}</div>
                </PaginationLink>
              )
            })
        }
      </>

      <PaginationLink onClick={() => {
        setCurrentPage(clamp(currentPage + 1, firstPage, lastPage - 1))
      }}>
        <Icon name={IconName.KEYBOARD_ARROW_RIGHT} screenReaderText={'Gå til neste side'} />
      </PaginationLink>

      <PaginationLink
        onClick={() => {
          setCurrentPage(lastPage - 1)
        }}
        isLast={true}
      >
        <Icon name={IconName.ARROW_FORWARD} screenReaderText={'Gå til siste side'} />
      </PaginationLink>
    </div>
  )
};
