import * as React from "react";
import { trackPageView } from '../../utils';
import {
  EventType,
  ObjectType,
  getInstance,
  initialize,
} from '@snoam/mono-pulse';
import { withRouter, RouteComponentProps, generatePath } from 'react-router';
import { routes } from "../routes";
import { MinBedriftRoute } from '../utils';
import {createContext, useEffect} from 'react';
import { ClientShortHandName } from '@snoam/mono-scc';
import {useSpid} from "@snoam/mono-spid/lib/spid";

let timeout: NodeJS.Timer;

const debouncePulseEvent = (wait: number, func: any) => {
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      //@ts-ignore
      func.apply(this, []);
    }, wait);
  }
}

type TrackerProps = RouteComponentProps<{ agreementNumber?: string }> & any;

const isCurrentPath = ({ match, location }: any) => (route: MinBedriftRoute<any>) => {
  try {
    return generatePath(route.path, match.params) === location.pathname;
  } catch (e) {
    return false;
  }
}

export interface IPulseTrackerContext {
  state: { message: string[] }
  action: { setTrackMessage: any};
}

export const PulseTrackerContext = createContext<IPulseTrackerContext>({} as IPulseTrackerContext);

export const PulseTrackerProvider = ({ children }: { children?: React.ReactNode }) => {
  const [message, setMessage] = React.useState<string[]>(['']);
  const spid = useSpid();
  const pulse = getInstance();

  const setTrackMessage = (trackMessage: string) => {
    message.pop();
    message.push(trackMessage);
    setMessage(message);
  };

  useEffect(() => {
    initialize(ClientShortHandName.BEDRIFT);
  }, []);
  useEffect(() => {
    if(spid.state.userId) {
      pulse.setUser(spid.state.userId.toString());
    }
  }, [spid.state.userId])
  const context = {
    state: {
      message
    },
    action: {
      setTrackMessage: setTrackMessage,
    }
  };
  return (
    <PulseTrackerContext.Provider value={context}>
      {children}
    </PulseTrackerContext.Provider>
  )
};

//@ts-ignore
export const Tracker: React.FunctionComponent<TrackerProps> = withRouter(({ children, location, match }) => {
  const pulseContext = React.useContext(PulseTrackerContext);
  const message = pulseContext.state.message;
  pulseContext.action.setTrackMessage('');

  let matchingRoute: MinBedriftRoute<any> = undefined;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [k, v] of Object.entries(routes)) {
    matchingRoute = v.routes.find(isCurrentPath({ match, location }));
    if (matchingRoute) {
      break;
    }
  }

  if(!matchingRoute) {
    matchingRoute = {
      trackingLabel : 'Feil - ',
      label: '404'
    }
  }

  const {trackinglabel = '', label} = matchingRoute;

  const pulseTracker = debouncePulseEvent(1500, () =>
    trackPageView({
      label: trackinglabel + label + message,
      labelPrefix: 'Min Bedrift',
      objectType: ObjectType.CONTENT,
      eventType: EventType.VIEW,
      id: `content:minbedrift:${(trackinglabel + label + message).toLowerCase().replace(/ /g, "")}`
    }));
  React.useLayoutEffect(pulseTracker, []);
  return <>{children}</>
});
