import {
  BackgroundColor,
  BorderRadius,
  Button,
  classNames,
  Display,
  Icon,
  IconColor,
  IconName,
  IconSize,
  LineHeight,
  Margin,
  Padding,
  Fill,
  FontSize,
  Whitespace,
  Width,
  FlexWrap,
  TextTransform,
  JustifyContent,
  FlexDirection,
  ButtonSkin
} from '@snoam/pinata';
import * as React from 'react';
import {useContext} from 'react';
import AgreementsCard from './AgreementsCard';
import {excludeLastItem, hasOneOrMoreItems} from '../../utils';
import {ModalContext} from "@snoam/mono-modal";
import {
  GetAgreements_me_agreements_edges,
  GetAgreements_me_agreements_edges_admins
} from '../../__generated__/GetAgreements';
import {RouteComponentProps, withRouter} from 'react-router';
import {RbacSuperAdminOnly} from "../Boundary";
import AdministrationModal from "../AdministrationModal/AdministrationModal";
import AdminModel from "../../models/AdminModel";

const styleClass = {
  agreement: {
    expandedBody: {
      card: {
        svg: {
          admin: classNames(
            Padding.P_1,
            BorderRadius.ROUNDED_FULL,
            Margin.MR_4,
            Margin.MT_1,
            BackgroundColor.BG_NEUTRAL_4,
          ),
        },
        addAdministratorButton: classNames(
          Padding.PL_3,
          Width.MD_W_AUTO,
          Margin.MX_AUTO,
          Width.W_64,
          Width.LG_W_64,
          Margin.MT_4,
          Margin.MB_0,
        ),
        subscriptionsButton: classNames(
          Width.MD_W_AUTO,
          Width.LG_W_64,
          Width.W_64,
          Margin.MX_AUTO,
        ),
        adminWrapper: classNames(
          Display.FLEX,
          FlexWrap.FLEX_WRAP,
          Margin.MT_2,
          LineHeight.LEADING_HEADLINE3,
          FontSize.TEXT_SM
        ),
        adminName: classNames(
          Width.W_AUTO,
          Whitespace.WHITESPACE_NO_WRAP,
          Margin.MR_1,
          TextTransform.CAPITALIZE,
        ),
        buttons: classNames(
          Display.FLEX,
          JustifyContent.JUSTIFY_CENTER,
          FlexDirection.FLEX_COL,
        ),
      },
    },
  },
};

const renderAdmins = (admins: GetAgreements_me_agreements_edges_admins[]) => {
  return admins.map((admin, i) => {
    return (
      <span
        key={admin.id}
        className={styleClass.agreement.expandedBody.card.adminName}
      >
        {`${(admin.name || {formatted: ''}).formatted}${excludeLastItem(i, admins) ? '' : ','}`}
      </span>
    )
  })
};

interface IAgreementCardAdminsProps {
  admins: GetAgreements_me_agreements_edges_admins[];
  agreement: Pick<GetAgreements_me_agreements_edges, 'agreementNumber' | 'agreementSubscriberCount'>
}

const AgreementCardAdmins: React.FunctionComponent<IAgreementCardAdminsProps & RouteComponentProps> = ({admins, agreement}) => {
  const modalId = 'AdministrationModal';
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const toggleModal = () => openModalId ? closeModal() : openModal(modalId);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      e.preventDefault();
      toggleModal();
    }
  };

  return (
    <>
      <AdministrationModal
        id={modalId}
        agreementNumber={agreement.agreementNumber}
        roles={AdminModel.AdministrationRoles}
        roleTitle={'Administrator'}
      />

      <AgreementsCard
        title={`${hasOneOrMoreItems(admins) ? `${admins.length} Administrator${admins.length === 1 ? '' : 'er'}` : 'Ingen administratorer'}`}
        pathName={`${agreement.agreementNumber}/avtale?tab=admins`}
        icon={
          <Icon
            size={IconSize.MEDIUM}
            key={`${IconName.BEDRIFT_ADMIN}`}
            name={IconName.BEDRIFT_ADMIN}
            color={IconColor.PRIMARY_LIGHT}
            className={styleClass.agreement.expandedBody.card.svg.admin}
          />
        }
      >
        <>
          <div className={styleClass.agreement.expandedBody.card.adminWrapper}>
            {renderAdmins(admins)}
          </div>
          <div className={styleClass.agreement.expandedBody.card.buttons}>
            <RbacSuperAdminOnly>
              <Button
                text={"Legg til administrator"}
                ariaLabel={"Legg til administrator"}
                skin={ButtonSkin.SECONDARY}
                className={styleClass.agreement.expandedBody.card.addAdministratorButton}
                style={{transition: 'background-color 500ms ease'}}
                iconLeft={
                  <Icon
                    key={`${IconName.ADD}`}
                    name={IconName.ADD}
                    className={classNames(Fill.FILL_NEUTRAL_6)}
                  />
                }
                onClick={toggleModal}
                onKeyDown={handleKeyDown}
              />
            </RbacSuperAdminOnly>
          </div>
        </>
      </AgreementsCard>
    </>
  )
};

export default withRouter(AgreementCardAdmins);
