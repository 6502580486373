import {Arrow, Button, ButtonSkin, classNames, Icon, IconName, Margin, MaxWidth, Width} from '@snoam/pinata';
import * as React from 'react';
import { useState } from 'react';
import {
  GetAgreement,
  GetAgreement_me_agreementsWithDetails_subscriptions,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_stopOrders,
  GetAgreementVariables
} from '../../__generated__/GetAgreement';
import {DefaultContext, InMemoryCache, MutationFunction, MutationUpdaterFunction, useMutation} from '@apollo/client';
import { checkNoErrors, DELETE_SUBSCRIPTION_CHANGE } from '../../mutations';
import {
  DeleteSubscriptionChange,
  DeleteSubscriptionChangeVariables
} from '../../__generated__/DeleteSubscriptionChange';
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';
import { GET_AGREEMENT } from '../../queries';

const styleClass = {
  button: classNames(
    Width.W_FULL,
    MaxWidth.MAX_W_SM,
  ),
};

export interface IUndoTerminateSubscriptionButtonProps {
  agreementNumber: number;
  assetNumber: string;
  classNames?: string;
  stopOrders: Array<Pick<GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_stopOrders, 'id' | 'date'>>;
}

const UndoTerminateSubscriptionButton: React.FunctionComponent<IUndoTerminateSubscriptionButtonProps> = (props) => {
  const [error, setError] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const deleteSubscriptionChange: MutationFunction<DeleteSubscriptionChange, DeleteSubscriptionChangeVariables, DefaultContext, InMemoryCache> = useMutation<DeleteSubscriptionChange, DeleteSubscriptionChangeVariables>(DELETE_SUBSCRIPTION_CHANGE)[0];
  const { agreementNumber, assetNumber, stopOrders } = props;
  const getAgreementVariables = (): GetAgreementVariables => {
    return {
      agreementNumber: [agreementNumber],
      shouldFetchSubscriptions: true,
    };
  };

  const update: MutationUpdaterFunction<DeleteSubscriptionChange, DeleteSubscriptionChangeVariables, DefaultContext, InMemoryCache> = (proxy, mutationResult) => {
    const data = proxy.readQuery<GetAgreement, GetAgreementVariables>({query: GET_AGREEMENT, variables: getAgreementVariables()});
    if (data && data.me && data.me.agreementsWithDetails && data.me.agreementsWithDetails.length === 1) {
      const subscriptions: GetAgreement_me_agreementsWithDetails_subscriptions[] = data.me.agreementsWithDetails[0].subscriptions;
      const subscription: undefined | GetAgreement_me_agreementsWithDetails_subscriptions = subscriptions.find(s => s.subscriptionItems.find(si => si.assetNumber === assetNumber));
      if (subscription) {
        const subscriptionItem = subscription && subscription.subscriptionItems.find(si => si.assetNumber === assetNumber);
        if (subscriptionItem && subscriptionItem.stopOrders) {
          subscriptionItem.agreementItemEndDate = null;
          subscriptionItem.stopOrders = subscriptionItem.stopOrders.filter(so => so.id !== stopOrders[0].id)
        }
      }
      proxy.writeQuery({query: GET_AGREEMENT, variables: getAgreementVariables(), data});
    }
  };
  const variables: DeleteSubscriptionChangeVariables = {
    agreementNumber,
    assetNumber,
    id: stopOrders[0].id,
  };

  const asyncAction = async () => {
    setError('');
    setIsDeleting(true);
    return deleteSubscriptionChange({ awaitRefetchQueries: true, variables, update})
      .then((r) => {
        setIsDeleting(false);
        return r ? checkNoErrors(r) : Promise.reject(`Expected return value, got: ${r}`)
      })
      .catch((e) => {
        setIsDeleting(false);
        setError(e && e.message || e.toString());
      });
  };
  return (
    <div className={classNames(styleClass.button, props.classNames)}>
      <Button
        text={"Angre avslutning"}
        ariaLabel={"Angre avslutning"}
        skin={ButtonSkin.SECONDARY}
        className={classNames(Width.W_FULL, Margin.MY_0)}
        iconLeft={
          <Icon name={IconName.UNDO}/>
        }
        loading={isDeleting}
        loadingText="Sletter endring..."
        onClick={asyncAction}
      />
      {error && <MessageBox message={error} type={MessageBoxType.ERROR} arrow={Arrow.TOP_CENTER}/>}
    </div>
  );
};

export default UndoTerminateSubscriptionButton;
