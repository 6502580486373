import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  Card,
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Link,
  Margin,
  Padding,
  RadioButton,
  RadioButtonGroup,
  Search,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  Arrow,
  FlexDirection,
  AlignContent,
  AlignItems,
  ButtonSkin,
  IconName
} from '@snoam/pinata';
import {Modal, ModalContext} from "@snoam/mono-modal";
import * as React from 'react';
import {ChangeEvent, KeyboardEvent, useContext, useEffect, useState} from 'react';
import {IAdminRole} from '../../models/AdminModel';
import {MutationFunction, useMutation, useQuery} from "@apollo/client";
import {
  GET_ADMIN_SEARCH_BY_EMAIL,
  GET_AGREEMENTS,
  GET_AGREEMENT,
  GET_SUPER_ADMINS,
  GET_ADMIN_SEARCH_BY_ID,
} from "../../queries";
import {FindAdmin, FindAdmin_findByEmail, FindAdminVariables} from "../../__generated__/FindAdmin";
import {AddAdmin, AddAdminVariables} from "../../__generated__/AddAdmin";
import {ADD_ADMIN, checkNoErrors} from "../../mutations";
import {debounceEvent} from "../../utils";
import { GetAgreement, GetAgreement_me_agreementsWithDetails_admins } from '../../__generated__/GetAgreement';
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';
import { useSpid } from '@snoam/mono-spid';
import { GetSuperAdmins } from '../../__generated__/GetSuperAdmins';
import { RbacSuperAdminOnly } from '../Boundary';
import { ChangeEventHandler } from 'react';
import {
  FindAdminById,
  FindAdminById_findBySchibstedAccountId,
  FindAdminByIdVariables,
} from '../../__generated__/FindAdminById';

const styleClass = {
  modal: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      AlignContent.CONTENT_CENTER,
      Padding.PX_1,
      Padding.MD_PX_10
    ),
    heading: classNames(
      FontSize.TEXT_2XL,
      FontWeight.FONT_HAIRLINE,
      TextAlign.TEXT_CENTER,
      Margin.MB_6
    ),
    headingTwo: classNames(
      FontSize.TEXT_XL,
      FontWeight.FONT_HAIRLINE,
      TextAlign.TEXT_CENTER,
      Margin.MT_6,
      Margin.MB_2,
    ),
    addButton: {
      root: classNames(
        Margin.MY_6,
        Width.MD_W_4_5,
        Width.W_FULL,
        FontWeight.FONT_NORMAL,
        Padding.PL_3
      ),
      wrapper: classNames(
        Display.FLEX,
        FlexDirection.FLEX_COL,
        AlignItems.ITEMS_CENTER
      )
    },
    text: classNames(
      Display.BLOCK,
      FontSize.TEXT_SM,
      Margin.MT_2,
      Margin.MB_6
    ),
    closeButton: classNames(
      classNames(
        Margin.MT_8,
        Margin.MB_1,
        BackgroundColor.BG_TRANSPARENT,
        BackgroundColor.HOVER_BG_TRANSPARENT,
        TextColor.TEXT_NEUTRAL_6,
        FontWeight.FONT_HAIRLINE
      )
    )
  },
};

interface IAdministrationModalProps {
  id: string;
  roles: IAdminRole[];
  roleTitle: string;
  agreementNumber?: number;
}

interface AddAdminBtnProps {
  searchResult: FindAdmin_findByEmail | null;
  role: IAdminRole
  agreementNumber: number | null;
  agreement: GetAgreement | undefined;
  resetAdminResultState: () => void;
}

const AddAdminBtn: React.FunctionComponent<AddAdminBtnProps> = ({searchResult, role, agreementNumber = undefined, agreement, resetAdminResultState}) => {
  const {data = {superAdmins: []}} = useQuery<GetSuperAdmins>(GET_SUPER_ADMINS);
  const spidContext = useSpid();

  const existingAdmins = agreement && agreement.me && agreement.me.agreementsWithDetails[0].admins as GetAgreement_me_agreementsWithDetails_admins[];
  const existingSuperAdmin = data && data.superAdmins;
  const searchTermUserId = searchResult && searchResult.userId;
  const searchTermUserName = searchResult && searchResult.name && searchResult.name.formatted;

  // eslint-disable-next-line eqeqeq
  const currentUserIsSuperAdmin = searchTermUserId == spidContext.state.userId;

  const searchTermUserIsSuperAdmin = existingSuperAdmin && existingSuperAdmin.some((superAdmin) => {
    return superAdmin.spid.toString() === searchTermUserId;
  });

  const searchTermUserIsAdmin = existingAdmins && existingAdmins.some((admin) => {
    return admin.spid.toString() === searchTermUserId;
  });

  const {closeModal} = useContext(ModalContext);
  const addAdminMutation: MutationFunction<AddAdmin, AddAdminVariables> = useMutation<AddAdmin, AddAdminVariables>(ADD_ADMIN)[0];
  const refetchQueries = [{ query: GET_SUPER_ADMINS }, {query: GET_AGREEMENT, variables: {agreementNumber: [agreementNumber && typeof agreementNumber === "number"  ? agreementNumber : undefined]}}, {query: GET_AGREEMENTS}];
  const addAction = () => addAdminMutation({
    refetchQueries,
    variables: {
      admin: {
        spid: searchResult ? +searchResult.id : -1,
        adminType: role.id,
        agreementNumber: agreementNumber && typeof agreementNumber === "number"  ? agreementNumber : undefined
      }
    }
  }).then(checkNoErrors).then(closeModal).then(() => {
    resetAdminResultState();
  });

  return (
    <>
    <Button
      disabled={!searchResult || !role.id || searchTermUserIsAdmin || currentUserIsSuperAdmin || searchTermUserIsSuperAdmin}
      text={`Legg til ${(role.label || '').toLowerCase()}`}
      ariaLabel={`Legg til ${(role.label || '').toLowerCase()}`}
      skin={ButtonSkin.PRIMARY}
      className={styleClass.modal.addButton.root}
      onClick={addAction}
    />
    {searchTermUserIsAdmin || currentUserIsSuperAdmin || searchTermUserIsSuperAdmin ?
      <div className={Margin.MB_3}>
        <MessageBox
          message={`${searchTermUserName} er allerede ${currentUserIsSuperAdmin || searchTermUserIsSuperAdmin ? 'Schibsted' : ''} administrator og har tilgang til alle avtaler.`}
          type={MessageBoxType.INFO}
          arrow={Arrow.TOP_CENTER}
        />
      </div>
    : null}
    </>
  )
};

interface SearchResultPropsBase {
  onResult: (result: FindAdmin_findByEmail | null) => void
  onLoading: (loading: boolean) => void;
}
interface SearchResultEmailProps extends SearchResultPropsBase {
  email: string;
}
interface SearchResultSchibstedAccountIdProps extends SearchResultPropsBase {
  schibstedAccountId: string;
}
type SearchResultProps = SearchResultEmailProps | SearchResultSchibstedAccountIdProps;

function isSearchResultEmailProps(props: SearchResultEmailProps | SearchResultSchibstedAccountIdProps): props is SearchResultEmailProps {
  return Object.getOwnPropertyNames(props).indexOf('email') !== -1;
}
function isFindAdmin(data: FindAdmin | FindAdminById): data is FindAdmin {
  return Object.getOwnPropertyNames(data).indexOf('findByEmail') !== -1;
}

const SearchResult: React.FunctionComponent<SearchResultProps> = (props) => {
  const { onResult, onLoading } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let {data, loading} = isSearchResultEmailProps(props) ? useQuery<FindAdmin, FindAdminVariables>(GET_ADMIN_SEARCH_BY_EMAIL, {
    variables: {email: props.email},
    fetchPolicy: "cache-first"
    // eslint-disable-next-line react-hooks/rules-of-hooks
  }) : useQuery<FindAdminById, FindAdminByIdVariables>(GET_ADMIN_SEARCH_BY_ID, {
    variables: { schibstedAccountId: props.schibstedAccountId },
    fetchPolicy: "cache-first"
  });

  useEffect(()=>{
    onLoading(loading);
  }, [loading]);

  if(!data) {
    onResult(null);
    return null;
  }

  const result: FindAdmin_findByEmail | FindAdminById_findBySchibstedAccountId | null = isFindAdmin(data) ? data.findByEmail : data.findBySchibstedAccountId;

  onResult(result);

  const {name = null, userId = null, email = null} = result || {};

  let content;
  if (name && userId) {
    content = <pre>{`${userId} - ${name!.formatted}${isSearchResultEmailProps(props) ? '' : (email ? ` (${email})` : '')}`}</pre>;
  } else if (isSearchResultEmailProps(props) ? props.email : props.schibstedAccountId) {
    content = <pre>Ingen resultater</pre>
  } else {
    return null;
  }

  return (
    <Card className={classNames(Width.W_FULL, TextAlign.TEXT_CENTER)}>
      {content}
    </Card>
  )
};

interface ManualSpidInputProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  roleTitle: string;
}
const ManualSpidInput: React.FunctionComponent<ManualSpidInputProps> = ({ onChange, roleTitle }) => {
  return (
    <label>
      <Heading
        level={HeadingLevel.THREE}
        className={styleClass.modal.headingTwo}>
        Du kan også legge til {roleTitle.toLowerCase()} ved å angi Schibsted-konto id manuelt
      </Heading>
      <Search
        onChange={onChange}
        onKeyDown={(evt: KeyboardEvent) => evt.key === 'Enter' ? evt.preventDefault() : null}
        aria-label={`Legg til ${roleTitle.toLowerCase()} ved å angi Schibsted-konto id manuelt`}
      />
    </label>
  );
};

interface AdministrationModalSearchByEmailProps extends Pick<SearchResultProps, 'onResult'> {
  roleTitle: string;
}
const AdministrationModalSearchByEmail: React.FunctionComponent<AdministrationModalSearchByEmailProps> = ({ onResult, roleTitle }) => {
  const [email, setEmail] = useState<string>('');
  const [schibstedAccountId, setSchibstedAccountId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setEmail(value);
  };
  const onChangeSchibstedAccountId: ChangeEventHandler<HTMLInputElement> = (__event) => {
    const value: string = __event.target.value;
    setSchibstedAccountId(value);
    onResult({
      __typename: 'FindByEmailReturnValue',
      email: null,
      id: value,
      name: null,
      userId: value,
    });
  };
  return (
    <div className={loading ? 'loading' : ''}>
      <Search
        iconName={loading ? IconName.LOADING_WHEEL : IconName.SEARCH}
        onChange={debounceEvent<ChangeEvent<HTMLInputElement>>(onChange, 1000)}
        onKeyDown={(evt: KeyboardEvent) => evt.key === 'Enter' ? evt.preventDefault() : null}
        aria-label={"Søk"}
      />
      {email ? <SearchResult email={email} onResult={onResult} onLoading={setLoading}/> : <RbacSuperAdminOnly>
        <ManualSpidInput onChange={debounceEvent<ChangeEvent<HTMLInputElement>>(onChangeSchibstedAccountId, 300)} roleTitle={roleTitle}/>
        <SearchResult schibstedAccountId={schibstedAccountId} onResult={onResult} onLoading={setLoading}/>
      </RbacSuperAdminOnly>}
    </div>
  );
};

const AdministrationModal: React.FunctionComponent<IAdministrationModalProps> = ({id, roles, roleTitle, agreementNumber = null}) => {
  const {data} = useQuery<GetAgreement>(GET_AGREEMENT, {
    variables: {agreementNumber},
    fetchPolicy: "cache-first"
  });

  const [adminResult, setAdminResult] = useState<FindAdmin_findByEmail | null>(null);
  const [defaultRole] = roles;
  const [role, setRole] = useState(defaultRole);
  const {closeModal} = useContext(ModalContext);
  return (
    <Modal
      id={id}
      backdrop={true}
      onClose={() => {
        setAdminResult(null)
        closeModal()
      }}
      className={BackgroundColor.BG_NEUTRAL_1}
    >
      <div className={styleClass.modal.root}>
        <Heading
          level={HeadingLevel.TWO}
          className={styleClass.modal.heading}>
          Legg til {roleTitle.toLowerCase()}
        </Heading>

        <AdministrationModalSearchByEmail
          onResult={adminResult => setAdminResult(adminResult)}
          roleTitle={roleTitle}
        />

        <span className={styleClass.modal.text}>
            Tips: Søk på e-post som er registrert med
              <Link
                href={'https://info.privacy.schibsted.com/no/hva-er-en-schibsted-konto/'}
                target={'_blank'}> Schibsted-konto
              </Link>
            . Dette kan være brukerens private e-post.
          </span>

        <RadioButtonGroup
          name={'rights'}
          value={defaultRole.id}
        >
          {
            roles.map((role, i: number) => {
              return (

                <div key={`${role.id}_${i}`}
                     className={
                       classNames(
                         BorderStyle.BORDER_SOLID,
                         BorderWidth.BORDER_B_1,
                         BorderColor.BORDER_NEUTRAL_3,
                         FontWeight.FONT_BOLD
                       )
                     }>
                  <RadioButton
                    // skin={RadioButtonSkin.PRIMARY_3}
                    className={FlexDirection.FLEX_COL}
                    value={role.id}
                    text={role.label}
                    onClick={() => setRole(role)}
                    checked={role.id === defaultRole.id}
                  />
                </div>
              )
            })
          }
        </RadioButtonGroup>

        <div className={styleClass.modal.addButton.wrapper}>
          <AddAdminBtn
            role={role}
            searchResult={adminResult}
            agreementNumber={agreementNumber}
            agreement={data}
            resetAdminResultState={() => setAdminResult(null)}
          />
          <span className={classNames(FontSize.TEXT_SM, TextAlign.TEXT_CENTER)}>
            {roleTitle} kan se alle avtaler lagt inn i Min bedrift
          </span>
        </div>

        <Button
          onClick={() => {
            setAdminResult(null);
            closeModal();
          }}
          text={'Avbryt'}
          ariaLabel={'Avbryt'}
          className={styleClass.modal.closeButton}
        />
      </div>
    </Modal>
  )
};

export default AdministrationModal;
