import * as React from 'react';
import {RouterProps, withRouter} from "react-router";

interface IPathBoundary extends RouterProps {
  excludeIfUrl: string;
  elementToRender?: JSX.Element;
  children?: any;
}

const PathBoundary: React.FunctionComponent<IPathBoundary> = (props) => {
  if (props.history.location.pathname.includes(props.excludeIfUrl)) {
    return props.elementToRender || null;
  }
  return props.children;
};

export default withRouter<any>(PathBoundary);
