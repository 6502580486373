import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useSpid} from "@snoam/mono-spid";
import {withRouter} from "react-router";
import {AdminType} from "../../__generated__/globalTypes";
import {ApolloError, useQuery} from "@apollo/client";
import {GetRole, GetRoleVariables} from "../../__generated__/GetRole";
import {GET_ROLE} from "../../queries";
import {MinBedriftRouteComponentProps} from "../../routes/utils";


export type MaybeAdminType = AdminType | null | undefined;
type MaybeApolloError = ApolloError | undefined;

export interface IRoleBoundary extends MinBedriftRouteComponentProps<{ agreementNumber: string }> {
  role: AdminType | AdminType[];
  children: (props: { roleBoundaryState: RoleBoundaryState, error?: Error, role: MaybeAdminType }) => any;
}

export enum RoleBoundaryState {
  NOT_LOGGED_IN,
  LOADING,
  NO_ACCESS,
  ERROR,
  HAS_ACCESS
}

type RoleContextType = {
  roleBoundaryState: RoleBoundaryState,
  error: MaybeApolloError,
  role: MaybeAdminType,
}

const RoleContext = React.createContext<RoleContextType>({
  roleBoundaryState: RoleBoundaryState.LOADING,
  error: undefined,
  role: undefined
});

export const useRole = () => {
  return useContext(RoleContext);
};

const RoleBoundary: React.FunctionComponent<IRoleBoundary> = (props) => {
  const {children, match, role} = props;

  const roles = !Array.isArray(role) ? [role] : role;
  const spidContext = useSpid();
  const [roleBoundaryState, setRoleBoundaryState] = useState<RoleBoundaryState>(RoleBoundaryState.NO_ACCESS);
  const [roleError, setRoleError] = useState<MaybeApolloError>(undefined);
  const [userRole, setUserRole] = useState<MaybeAdminType>(undefined);

  const {state: {userId, isLoaded}} = spidContext;
  const variables = {
    agreementNumber: match.params.agreementNumber ? +match.params.agreementNumber : undefined,
  };

  const {data, error, loading} = useQuery<GetRole, GetRoleVariables>(GET_ROLE, {
    variables,
    fetchPolicy: 'cache-first',
  });

  let {
    me: {
      role: apiRole = undefined
    } = {}
  } = data || {};

  useEffect(()=> {
    if (!isLoaded) {
      setRoleBoundaryState(RoleBoundaryState.LOADING);
    } else if (!userId) {
      setRoleBoundaryState(RoleBoundaryState.NOT_LOGGED_IN);
    } else if (userId) {

      //note the diff in apiRole -- undefined vs null -- undefined means that role is not initialized
      if ((loading || typeof apiRole === 'undefined') && roleBoundaryState !== RoleBoundaryState.LOADING) {
        setRoleBoundaryState(RoleBoundaryState.LOADING);
      } else if (error && roleBoundaryState !== RoleBoundaryState.ERROR) {
        setRoleBoundaryState(RoleBoundaryState.ERROR);
        setRoleError(error);
      } else if (apiRole && roles.indexOf(apiRole) > -1 && roleBoundaryState !== RoleBoundaryState.HAS_ACCESS) {
        setRoleBoundaryState(RoleBoundaryState.HAS_ACCESS);
        setUserRole(apiRole);
      } else {
        setRoleBoundaryState(RoleBoundaryState.NO_ACCESS)
      }
    }
  }, [isLoaded, loading, userId]);

  const childProps = {
    roleBoundaryState,
    error: roleError,
    role: userRole
  };

  return (<>
    <RoleContext.Provider value={childProps}>
      {children(childProps)}
    </RoleContext.Provider>
  </>);
};
export default withRouter(RoleBoundary);
