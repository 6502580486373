import * as React from "react";
import {AdminType} from "../../../__generated__/globalTypes";
import {RouteComponentProps} from "react-router";
import AdminBoundary from "./AdminBoundary";

export const RoleBoundary: React.FunctionComponent<{ role: AdminType | AdminType[] }> = ({children, role, ...otherProps}) => (
  <AdminBoundary
    {...otherProps as RouteComponentProps<{ agreementNumber: string }>}
    role={role}
  >
    {children}
  </AdminBoundary>
);

export const SuperAdminOnlyBoundary: React.FunctionComponent = ({children, ...otherProps}) => (
  <RoleBoundary
    children={children}
    role={[AdminType.SUPERADMIN]}
    {...otherProps}
  />
);

export const SuperAdminOrSuperObserverBoundary: React.FunctionComponent = ({children, ...otherProps}) => (
  <RoleBoundary
    children={children}
    role={[AdminType.SUPERADMIN, AdminType.SUPEROBSERVER]}
    {...otherProps}
  />
);

export const SuperAdminOrAdminOrAccountantBoundary: React.FunctionComponent = ({children, ...otherProps}) => (
  <RoleBoundary
    children={children}
    role={[AdminType.SUPERADMIN, AdminType.SUPEROBSERVER, AdminType.ADMIN, AdminType.ACCOUNTANT]}
    {...otherProps}
  />
);
