import * as React from 'react';
import {Container, ContainerWidth,} from "@snoam/pinata";
import {useQuery} from "@apollo/client";
import {GET_AGREEMENT_PRODUCTS_AND_SIEBEL_PRODUCTS} from "../../queries";
import {LoadingFragment} from "../LoadingPage";
import AvailableProducts from "./AvailableProducts";
import ActivatedProducts from "./ActivatedProducts";
import {
  groupBy,
  mergeActiveAndGenericProducts,
  mergeProductDiscountFromActiveToAvailable,
  SyntheticAgreementProduct
} from "../../components/GroupProducts/GroupProducts";
import {
  GetAgreementProductsAndSiebelProducts,
  GetAgreementProductsAndSiebelProducts_availableProducts,
  GetAgreementProductsAndSiebelProductsVariables
} from "../../__generated__/GetAgreementProductsAndSiebelProducts";
import {MinBedriftRouteComponentProps} from "../../routes/utils";
import {Helmet} from "react-helmet";
import {sortMerchants} from '../../utils';
import {useRole} from "../../components/Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";

const availableProductsDivRef = React.createRef<HTMLDivElement>();

const ProductsPage: React.FunctionComponent<MinBedriftRouteComponentProps<{ agreementNumber: string }>> = ({match, route}) => {
  const agreementNumber: number = +match.params.agreementNumber;
  const {data, error, loading} = useQuery<GetAgreementProductsAndSiebelProducts, GetAgreementProductsAndSiebelProductsVariables>(GET_AGREEMENT_PRODUCTS_AND_SIEBEL_PRODUCTS, {
    variables: {agreementNumber: [agreementNumber], showOtherProducts: true},
  });
  const {role: userRole} = useRole();
  if (loading) {
    return <LoadingFragment/>;
  }

  if (error) {
    throw error;
  }

  const {availableProducts = [], me = {agreementsWithDetails: []}} = data as GetAgreementProductsAndSiebelProducts;
  const [agreement] = me.agreementsWithDetails;

  const {products, maxSubscriptionsPerEmployee, agreementDiscountPct} = agreement;

  const groupedActivated = groupBy('productGroup', products);
  const groupedAvailable = groupBy('productGroup', availableProducts);
  const productsAvailableWithDiscount = mergeProductDiscountFromActiveToAvailable(groupedActivated, groupedAvailable);

  const mergedProducts = mergeActiveAndGenericProducts(groupedActivated, groupedAvailable);
  Object
    .keys(groupedActivated)
    .forEach((groupName: string) => {
      const hasActiveProductInGroup = groupedActivated[groupName].some((p) => p.active);
      if(hasActiveProductInGroup) {
        delete groupedAvailable[groupName]
      } else {
        delete mergedProducts[groupName];
      }
    });

  const sortedActivated = sortMerchants(Object.keys(mergedProducts)).map(k => mergedProducts[k]) as SyntheticAgreementProduct[][];
  const sortedAvailable = sortMerchants(Object.keys(productsAvailableWithDiscount)).map(k => productsAvailableWithDiscount[k]) as GetAgreementProductsAndSiebelProducts_availableProducts[][];

  return (
    <>
      <Helmet>
        <title>{`Min Bedrift ${agreement ? `| ${agreement.accountName} ` : ''} ${route ? `| ${route.label}` : ''}`}</title>
      </Helmet>
      <Container width={ContainerWidth.WIDER}>
        <ActivatedProducts
          maxSubscriptionsPerEmployee={maxSubscriptionsPerEmployee || 0}
          agreementNumber={agreementNumber}
          availableProductsDivRef={availableProductsDivRef}
          products={sortedActivated}
          agreementDiscountPercent={agreementDiscountPct}
          userRole={userRole}
        />
        {userRole !== AdminType.SUPEROBSERVER &&
          <AvailableProducts
            availableProductsDivRef={availableProductsDivRef}
            activeProducts={sortedActivated}
            products={sortedAvailable}
            agreementNumber={agreementNumber}
            agreementDiscountPercent={agreementDiscountPct}
          />
        }
      </Container>
    </>
  )
};

export default ProductsPage;
