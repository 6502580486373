import React, {FunctionComponent} from "react";
import {Redirect, Route, RouteComponentProps, RouteProps} from "react-router";
import {Helmet} from "react-helmet";
import {IconName} from "@snoam/pinata";

export type MinBedriftRoute<P = {}> = RouteProps & P & {
  label: string;
  trackingLabel?: string,
  path: string;
  redirectFrom?: any[],
  component: React.ComponentType<any>;
  wrappers: React.ComponentType<any>[];
}

export type MinBedriftRouteWithIcon<P = {}> = MinBedriftRoute<{ icon: IconName } & P>;
export type MinBedriftSuperAdminRouteWithIcon<P = {}> = MinBedriftRouteWithIcon<{ appliesToAllRoles: boolean } & P>;

export type MinBedriftRouteParent = {
  wrappers: React.ComponentType[],
  routes: MinBedriftRoute[] | MinBedriftRouteWithIcon[] | MinBedriftSuperAdminRouteWithIcon[]
}

export type MinBedriftRoutes = {
  super: MinBedriftRouteParent,
  superObserver: MinBedriftRouteParent,
  admin: MinBedriftRouteParent,
  order: MinBedriftRouteParent,
  generic: MinBedriftRouteParent,
  login: MinBedriftRouteParent,
};

export interface MinBedriftRouteComponentProps<A extends {} = {}> extends RouteComponentProps<A>{
  route: MinBedriftRoute;
}

const wrapReduce = (wrappers: React.ComponentType<any>[], Base: React.ComponentType<any>, props = {}, route: MinBedriftRoute) => {
  return wrappers.reduce((child, Wrapper) => {
    return <Wrapper {...props} route={route}>{child}</Wrapper>
  }, <Base {...props} route={route}/>);
};

export const createRedirect = <P extends {}>(route: MinBedriftRoute<P>) => {
  const {path, redirectFrom = []} = route;
  return redirectFrom.reduce((acc: JSX.Element[], r: string | string[], i: number) => {
    if (Array.isArray(r)) {
      let [from, to] = r;
      acc.push(
        <Redirect
          key={`${route.label}_${i}`}
          to={to}
          from={from}
          exact={true}
        />
      );
    } else {
      acc.push(
        <Redirect
          key={`${route.label}_${i}`}
          to={path}
          from={r}
          exact={true}
        />
      );
    }
    return acc;
  }, []);
};

export const createRoute = <P extends {}>(route: MinBedriftRoute<P>, parentWrappers: React.ComponentType[] = [({children}) => <>{children}</>]) => {
  const {wrappers, label, component: BaseComponent, ...routeParams} = route;

  const renderRoute = (props: any) => {
    if (wrappers && wrappers.length > 0) {
      const CastedComponent = BaseComponent as FunctionComponent;
      const SingleRouteWrapped = wrapReduce(wrappers, CastedComponent, props, route);
      return (
        <>
          <Helmet>
            <title>Min Bedrift | {label}</title>
          </Helmet>
          {
            wrapReduce(parentWrappers, (props) => React.cloneElement(SingleRouteWrapped, {...SingleRouteWrapped.props, ...props}), props, route)}
        </>
      );
    } else {
      return (
        <>
          <Helmet>
            <title>Min Bedrift | {label}</title>
          </Helmet>
          {wrapReduce(parentWrappers, BaseComponent, props, route)}
        </>
      );
    }
  };

  return <Route
    key={label}
    render={renderRoute}
    {...routeParams}
  />
};
