import * as React from 'react';
import { ChangeEvent, KeyboardEvent, ReactElement } from 'react';

import {ApolloClient, ApolloLink, InMemoryCache, createHttpLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import { SpidContext } from "@snoam/mono-spid";
import {ClientName, ClientShortHandName, getClientId} from "@snoam/mono-scc";

import {GetAgreementOrderInitialLoggedOut_agreement_products} from "./__generated__/GetAgreementOrderInitialLoggedOut";
import {DesktopHeaderHeight, MobileHeaderHeight} from "./Const";
import {GetAgreementProducts_me_agreementsWithDetails_products} from './__generated__/GetAgreementProducts';
import {$PropertyType} from 'utility-types';
import {IAddress} from '@snoam/mono-address';
import introspectionQueryResultData from './__generated__/fragmentTypes.json';

import {
  EngagementAction,
  EventType,
  getInstance,
  IEngagementModel,
  IPageViewModel,
  ObjectType,
} from '@snoam/mono-pulse';
import {GetAgreements_me_agreements_edges_products} from "./__generated__/GetAgreements";
import { GetAgreementProductsAndSiebelProducts_availableProducts } from './__generated__/GetAgreementProductsAndSiebelProducts';
import { isArray } from 'util';
import { Identity } from '@schibsted/account-sdk-browser';
import {AccountNameCondition, AccountNameConditionOperator, AdminType} from "./__generated__/globalTypes";

const debug = require('debug')('minbedrift:client:utils');

export const teaser = () => /^\/bestill/.test(window.location.pathname) ? 'minbedrift-ansatt' : 'minbedrift-admin';

export const chunk = (arr: any[], chunkSize: number) => {
  const R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
};

export function excludeLastItem(index: number, list: any[]) {
  return index === list.length - 1;
}

export function hasOneOrMoreItems(list: any[]) {
  return list.length >= 1;
}

export function hasMoreThanOneItem(list: any[]) {
  return list.length > 1;
}

export function hasOnlyOneItem(list: any[]) {
  return list.length === 1;
}

export const getHeaderHeights = () => {
  return [
    MobileHeaderHeight,
    DesktopHeaderHeight
  ]
};

const createErrorLink = () => onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({message, locations, path}) =>
      debug(
        `[GraphQL error]: Message: ${message}, Location: [${(locations || []).map((l) => JSON.stringify(l)).join(", ")}], Path: ${path}`
      )
    );
  }
  if (networkError) {
    debug(`[Network error]: ${networkError}`);
  }
});

interface ApolloAuthMiddlewareSpidContext {
  actions: Pick<$PropertyType<SpidContext, 'actions'>, 'hasSession'>;
  state: Pick<$PropertyType<SpidContext, 'state'>, 'sig'>;
}
const apolloAuthMiddleware = (spidContext: ApolloAuthMiddlewareSpidContext) => setContext((operation, {headers}) =>
  spidContext!.actions.hasSession().then(data => {
    const headersWithAuth = {
      ...headers,
      authorization: data.sig ? `Bearer ${data.sig}` : null,
    };
    debug('Set auth, headersWithAuth: %o', headersWithAuth);

    return {headers: headersWithAuth};
  }).catch(error => {
    if (spidContext!.state.sig) {
      const headersWithAuth = {
        ...headers,
        authorization: `Bearer ${spidContext!.state.sig}`,
      };
      debug('Set auth from spidContext.state.sig, headersWithAuth: %o', headersWithAuth);
      return {headers: headersWithAuth};
    }
    debug('hasSession failed, not setting auth header: %o', error);
    return {headers};
  })
);

export const createApolloClient = (spidContext: ApolloAuthMiddlewareSpidContext) => {
  return new ApolloClient({
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only'
      }
    },
    cache: new InMemoryCache({
      possibleTypes: introspectionQueryResultData,
      resultCaching: true,
    }),
    connectToDevTools: true,
    link: ApolloLink.from([
      apolloAuthMiddleware(spidContext),
      createErrorLink(),
      createHttpLink({uri: '/graphql'}),
    ]),
    queryDeduplication: true,
  })
};

export interface Publication {
  clientShortHandName: ClientShortHandName;
  productGroup: string,
  originalLink: string,
  link: string,
  originalMyPage: string;
  myPage: string;
  logo: string;
}

export const publications: (isProduction: boolean) => Publication[] = (isProduction) => [
  {
    clientShortHandName: ClientShortHandName.AP,
    productGroup: 'AP_Aftenposten',
    link: 'https://ap.no/',
    myPage: 'https://kundeportal.aftenposten.no/minside',
    logo: '/svg/publications/aftenposten.svg'
  },
  {
    clientShortHandName: ClientShortHandName.ASK,
    productGroup: 'ASK_Ask_yv_ringen',
    link: 'https://av-avis.no/',
    myPage: 'https://kundeportal.av-avis.no/minside',
    logo: '/svg/publications/av-avis.svg',
  },
  {
    clientShortHandName: ClientShortHandName.BTI,
    productGroup: 'BTI_Bergens_Tidende',
    link: 'https://bt.no/',
    myPage: 'https://kundeportal.bt.no/minside',
    logo: '/svg/publications/bt.svg'
  },
  {
    clientShortHandName: ClientShortHandName.BYN,
    productGroup: 'BYN_Bygdanytt',
    link: 'https://bygdanytt.no/',
    myPage: 'https://kundeportal.bygdanytt.no/minside',
    logo: '/svg/publications/bygdanytt.svg',
  },
  {
    clientShortHandName: ClientShortHandName.DP,
    productGroup: 'DP_E24_Dine_Penger',
    link: 'https://e24dinepenger.no/',
    myPage: 'https://kundeportal.e24dinepenger.no/minside',
    logo: '/svg/publications/E24dinepenger.svg',
  },
  {
    clientShortHandName: ClientShortHandName.AP,
    productGroup: 'Forlag_Aftenposten_Historie',
    link: 'https://www.magasinpluss.no/#aftenposten-historie',
    myPage: 'https://kundeportal.aftenposten.no/minside',
    logo: '/svg/publications/Historie.svg',
  },
  {
    clientShortHandName: ClientShortHandName.AP,
    productGroup: 'Forlag_Hyttemagasinet',
    link: 'https://www.magasinpluss.no/#hyttemagasinet',
    myPage: 'https://kundeportal.aftenposten.no/minside',
    logo: '/svg/publications/hyttemagasinet.svg',
  },
  {
    clientShortHandName: ClientShortHandName.AP,
    productGroup: 'Forlag_Aftenposten_Innsikt',
    link: 'https://www.magasinpluss.no/#innsikt',
    myPage: 'https://kundeportal.aftenposten.no/minside',
    logo: '/svg/publications/innsikt.svg',
  },
  {
    clientShortHandName: ClientShortHandName.AP,
    productGroup: 'Forlag_Aftenposten_Junior',
    link: 'https://www.magasinpluss.no/#aftenposten-junior',
    myPage: 'https://kundeportal.aftenposten.no/minside',
    logo: '/svg/publications/junior.svg',
  },
  {
    clientShortHandName: ClientShortHandName.MPL,
    productGroup: 'Forlag_Magasin_',
    link: 'https://www.magasinpluss.no',
    myPage: 'https://kundeportal.aftenposten.no/minside',
    logo: '/svg/publications/magasinpluss.svg',
  },
  {
    clientShortHandName: ClientShortHandName.AP,
    productGroup: 'Forlag_Mat_fra_Norge',
    link: 'https://www.magasinpluss.no/#mat-fra-norge',
    myPage: 'https://kundeportal.aftenposten.no/minside',
    logo: '/svg/publications/MFN_magasin.svg',
  },
  {
    clientShortHandName: ClientShortHandName.SA,
    productGroup: 'SA_Stavanger_Aftenblad',
    link: 'https://aftenbladet.no',
    myPage: 'https://kundeportal.aftenbladet.no/minside',
    logo: '/svg/publications/sa.svg',
  },
  {
    clientShortHandName: ClientShortHandName.STR,
    productGroup: 'STR_Strilen',
    link: 'https://www.strilen.no/',
    myPage: 'https://kundeportal.strilen.no/minside',
    logo: '/svg/publications/Strilen.svg',
  },
  {
    clientShortHandName: ClientShortHandName.VNY,
    productGroup: 'VNY_Vestnytt',
    link: 'https://www.vestnytt.no/',
    myPage: 'https://kundeportal.vestnytt.no/minside',
    logo: '/svg/publications/vestnytt.svg',
  },
  {
    clientShortHandName: ClientShortHandName.VG,
    productGroup: 'VG_VG',
    link: 'https://www.vg.no/',
    myPage: 'https://kundeportal.vg.no/minside',
    logo: '/svg/publications/VG.svg',
  },
].map((p) => {
  const identity = new Identity({
    clientId: getClientId(p.clientShortHandName, isProduction),
    env: isProduction ? 'PRO_NO' : 'PRE',
    redirectUri: p.link,
  });
  return {
    clientShortHandName: p.clientShortHandName,
    productGroup: p.productGroup,
    originalLink: p.link,
    link: (identity as any).loginUrl({
      state: '1',
      redirectUri: p.link,
    }),
    originalMyPage: p.myPage,
    myPage: (identity as any).loginUrl({
      state: '1',
      redirectUri: p.myPage,
    }),
    logo: p.logo,
  };
});


export function buildPublicationsString(publications: GetAgreementOrderInitialLoggedOut_agreement_products[]): string {
  //const noDublicates: string[] = [];

  const noDublicates: string[] = Object.keys(
    publications.reduce((acc, pub) => {
      acc[pub.companyCode] = true;
      return acc;
    }, {})
  ).filter(s => s && ClientName[s]);

  return noDublicates
    .map((code, i) => i === noDublicates.length - 1 ? `og ${ClientName[code]}` : ClientName[code])
    .join(', ')
    .replace(/,([^,]*)$/, '$1');
}

export function debounceEvent<T extends { persist(): void }>(func: (evt: T) => void, wait = 100) {
  let timeout: NodeJS.Timer;
  return function (evt: T) {
    evt.persist();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      //@ts-ignore
      func.apply(this, [evt]);
    }, wait);
  };
}

export function delayEvent<T extends { persist(): void }>(func: (evt: ChangeEvent<T>) => void, wait = 100) {
  return debounceEvent(func, wait);
}

export type MySpidContext = {
  actions: Pick<$PropertyType<SpidContext, 'actions'>, 'hasSession'>;
};

export interface RequestInviteCodeArgs {
  address: IAddress;
  agreementNumber: number | string;
  email: string;
  selectedProducts: Array<Pick<GetAgreementProducts_me_agreementsWithDetails_products, 'siebelProductId'>>;
  products: {
    [k: string]: {
      companyCode: string;
      productCombination: string;
      productName: string;
    };
  };
  references: { [k: string]: { billingAgreementNumber?: number | string; reference1?: string; reference2?: string; } }
  spidContext: MySpidContext;
}

const checkSuccess: (response: Response) => Promise<Response> = async (result) => {
  if (result.status >= 200 && result.status < 300) {
    return result;
  }
  const body = await result.text();
  let errorMessage: ReactElement | string | null;
  try {
    const parsed = JSON.parse(body);
    if (typeof parsed === 'string') {
      errorMessage = parsed;
    } else if (Array.isArray(parsed)) {
      errorMessage = parsed.join(', ');
    } else if (parsed && typeof parsed.error === 'string') {
      errorMessage = parsed.error;
    } else {
      errorMessage = <ul>{Object.keys(parsed).map((k => <li>{k}: {parsed[k]}</li>))}</ul>;
    }
  } catch (ignored) {
    errorMessage = null;
  }
  throw new ScoreIntegrationError(`Bad HTTP status: ${result.status} - ${result.statusText} (${body})`, errorMessage);
};

type RequestInviteCode = (args: RequestInviteCodeArgs) => Promise<string>;

export const requestInviteCode: RequestInviteCode = (args) => {

  const {spidContext, agreementNumber, selectedProducts, ...rest} = args;
  return spidContext.actions.hasSession().then((data) => {
    if (data && data.sig) {
      const quotes = selectedProducts.map(p => p.siebelProductId).join(',');
      const rawRedirectPath = (window.location.pathname).replace(/\/brukerinformasjon/, '/invitasjonskode');
      const redirectUri = window.location.origin + rawRedirectPath;
      return fetch(`/bestilling/api/v3/business/agreements/${agreementNumber}/invitations.json`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-SCORE-SPID-SIG': data.sig,
        },
        body: JSON.stringify({
          ...rest,
          redirectUri,
          quotes,
        }),
      }).then(checkSuccess).then(() => {
        return rawRedirectPath;
      });
    }
    return Promise.reject(data || 'No session found');
  });

};

export class ScoreIntegrationError extends Error implements ScoreIntegrationError {
  errorMessage: ReactElement | string | null;

  constructor(message: string, errorMessage: ReactElement | string | null) {
    super(message);
    this.errorMessage = errorMessage;
  }
}

export interface RedeemInviteCodeArgs {
  agreementNumber: number | string;
  code: string;
}
export interface RedeemInviteCodeReturn {
  error: string;
  orderIds: string[];
  products: Array<{
    companyCode: string;
    productCombination: string;
    productName: string;
  }>;
  user: {
    givenName?: string;
    userId: number;
  };
}
type RedeemInviteCode = (args: RedeemInviteCodeArgs) => Promise<RedeemInviteCodeReturn>;
export const sendOrder: RedeemInviteCode = (args) => {

  const { agreementNumber, code } = args;
  return fetch(`/bestilling/api/v3/business/agreements/${agreementNumber}/invitations/${code}.json`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkSuccess)
    .then((res) => res.json());

};

type CreateOrderArgs = Omit<RequestInviteCodeArgs, "email"> & {
  openidConnectCode: string;
};
type CreateOrder = (args: CreateOrderArgs) => Promise<RedeemInviteCodeReturn>;
export const createOrder: CreateOrder = (args) => {
  const {spidContext, agreementNumber, selectedProducts, ...rest} = args;
  return spidContext.actions.hasSession().then((data) => {
    if (data && data.sig) {
      const quotes = selectedProducts.map(p => p.siebelProductId).join(',');
      const rawRedirectPath = (window.location.pathname).replace(/\/til-ekstern-login/, '/fra-ekstern-login');
      const redirectUri = window.location.origin + rawRedirectPath;
      return fetch(`/bestilling/api/v3/business/agreements/${agreementNumber}/orders.json`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-SCORE-SPID-SIG': data.sig,
        },
        body: JSON.stringify({
          ...rest,
          redirectUri,
          quotes,
        }),
      }).then(checkSuccess)
        .then((res) => res.json());
    }
    return Promise.reject(data || 'No session found');
  });
};

export const checkInvitationCode: RedeemInviteCode = (args) => {
  const { agreementNumber, code } = args;
  return fetch(`/bestilling/api/v3/business/agreements/${agreementNumber}/verifycode/${code}.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(checkSuccess)
    .then((res) => res.json());
};

export interface FetchInvoiceArgs {
  accountNumber: number;
  agreementNumber: number;
  invoiceNumber: string;
}
export const fetchInvoiceHref: (args: FetchInvoiceArgs) => string = ({accountNumber, agreementNumber, invoiceNumber}) => {
  return `/api/invoices/${agreementNumber}/${accountNumber}/${invoiceNumber}.pdf`;
};

export const removeTypename = <T extends { __typename?: string }> (o: T): Omit<T, "__typename"> => {
  if (Array.isArray(o)) {
    return o;
  }
  if (o && typeof o === 'object') {
    const {__typename, ...rest} = o;
    return Object.keys(rest).reduce((a, e) => ({...a, [e]: removeTypename(o[e])}), {} as Omit<T, "__typename">);
  }
  return o;
};

export enum EventKey {
  ENTER = 'Enter'
}

export const onKeyPress = (event: KeyboardEvent<any>, callback: (args?: any) => void, eventKey: string) => {
  if (event.key === eventKey) {
    event.preventDefault();
    callback();
  }
};

export const clamp = (num: number, min: number, max: number) => {
  return num <= min ? min : num >= max ? max : num;
};

export function trackPageView(pageViewModel: IPageViewModel) {

  if (typeof window !== 'undefined' && window.hasOwnProperty('location')) {
    const pulse = getInstance();
    const pageView = pulse.createPageViewModel(pageViewModel);
    pulse.track(pageView);
  }
}

export function trackClickEngagment(clicklabel: string) {
  trackEngagment({
    label: clicklabel,
    action: EngagementAction.Click,
    type: EventType.ENGAGEMENT,
    labelPrefix: 'Min Bedrift',
    object: {
      id: `content:minbedrift:element:${clicklabel.toLocaleLowerCase().replace(/ /g, "")}`,
      type: ObjectType.UIELEMENT,
      elementType: 'Button',
      custom: {product_name: 'minbedrift'}
    },
    origin: {
      id: `content:minbedrift:${clicklabel.toLocaleLowerCase().replace(/ /g, "")}`,
      type: ObjectType.PAGE,
      // eslint-disable-next-line no-restricted-globals
      url: location.href
    }
  });
}

export function trackEngagment(engageModel: IEngagementModel) {
  if (typeof window !== 'undefined' && window.hasOwnProperty('location')) {
    const pulse = getInstance();
    const model = pulse.createEngagementModel(engageModel);
    pulse.track(model);
  }
}

const merchants = [
  'SCH_Schibstedprodukter',
  'AP_Aftenposten',
  'BTI_Bergens_Tidende',
  'DP_E24_Dine_Penger',
  'SA_Stavanger_Aftenblad',
  'FVN_F_drelandsvennen',
  "SYS_Sysla",

  'VG_VG',

  "Forlag_Aftenposten_Innsikt",
  "Forlag_Aftenposten_Historie",
  "Forlag_Magasin_",

  "ASK_Ask_yv_ringen",
  "BYN_Bygdanytt",
  "LIN_Lindesnes_Avis",
  "FAK_Lister24",
  "STR_Strilen",
  "ASB_S_gne_og_Songdalen_Budstikke",
  "VNY_Vestnytt",

  "Forlag_Aftenposten_Junior",
  "Forlag_Mat_fra_Norge",
  "Forlag_Hyttemagasinet",
];
export const sortMerchants = (array: string[]): string[] => array.sort((a, b) => {

  const indexA = merchants.indexOf(a);
  const indexB = merchants.indexOf(b);
  const sortA = indexA !== -1 ? indexA : 99;
  const sortB = indexB !== -1 ? indexB : 99;

  return sortA - sortB;
});


type GroupedProduct = {
  [x: string]: GetAgreements_me_agreements_edges_products[];
};
export const groupProductsByMerchant = (products: GetAgreements_me_agreements_edges_products[]) => products.reduce((acc: GroupedProduct, p: GetAgreements_me_agreements_edges_products) => {
  if (acc.hasOwnProperty(p.productName)) {
    acc[p.productName].push(p);
  } else {
    acc[p.productName] = [
      p
    ];
  }
  return acc;
}, {});

export const getDiscountPercentage = (agreementDiscountPercentage: number, products: GetAgreementProductsAndSiebelProducts_availableProducts[] | number): number => {
  if(isArray(products)) {
    const productDiscount = products.find((product: GetAgreementProductsAndSiebelProducts_availableProducts) => {
      return product.discountPercent > agreementDiscountPercentage;
    });
    return productDiscount ? productDiscount.discountPercent : agreementDiscountPercentage;
  }
  return agreementDiscountPercentage > products ? agreementDiscountPercentage : products;

};

export const isOrderFlow = () => {
  return window.location.href.includes("/bestill");
};

export const capitalize = (str: string) => {
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const simpleMarkdown = (str: string) => {
  return str
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2' style='text-decoration: underline; color: #1759E7'>$1</a>")
}

export const accountNameConditions = (): Array<AccountNameCondition[]> => {
  return [
    [{
      operator: AccountNameConditionOperator.lessThan,
      character: "A"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "A"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "D"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "D"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "G"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "G"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "J"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "J"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "M"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "M"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "P"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "P"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "S"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "S"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "V"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "V"
    }, {
      operator: AccountNameConditionOperator.lessThan,
      character: "Y"
    }],
    [{
      operator: AccountNameConditionOperator.greaterThan,
      character: "Y"
    }],
  ];
};

export const getMaintenanceUrl = () => {
  if(window.location.href.includes("test-") || window.location.href.includes("localhost") ) {
    return "https://test-kundeportal.aftenposten.no/wp-json/expose/settings"
  }
  return "https://kundeportal.aftenposten.no/wp-json/expose/settings"
}

export const isSuperOrObserver = (role: string) => {
  return role === AdminType.SUPERADMIN || role === AdminType.SUPEROBSERVER;
}
