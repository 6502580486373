import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Container,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  LineHeight, Link,
  Margin,
  MaxWidth,
  Padding,
  TextAlign,
} from "@snoam/pinata";
import {ProductBoxList} from "./ProductBoxList";
import {useQuery} from "@apollo/client";
import {LoadingFragment} from "../../LoadingPage";
import {EmptyAgreements} from "../../Agreements/EmptyAgreements";
import OrderButton from "../../../components/OrderButton/OrderButton";
import {GET_AGREEMENT_ORDER_INITIAL,} from '../../../queries';
import {OrderSessionModel, Product} from "../models/OrderSessionModel";
import {
  GetAgreementOrderInitial,
  GetAgreementOrderInitial_agreement_products,
  GetAgreementOrderInitialVariables
} from "../../../__generated__/GetAgreementOrderInitial";
import {MinBedriftRouteComponentProps} from "../../../routes/utils";
import UserBadge from "../../../components/UserBadge/UserBadge";
import {useSpid} from "@snoam/mono-spid";
import {useRole} from "../../../components/Boundary/RoleBoundary";
import {teaser} from '../../../utils';

const styleClass = {
  container: classNames(
    Margin.MB_8,
  ),
  pageHeading: classNames(
    FontFamily.FONT_TITLE_1,
    FontSize.TEXT_HEADLINE2,
    LineHeight.LEADING_HEADLINE2,
    Padding.PB_4,
    Padding.PT_16,
    Padding.SM_PX_0,
    Padding.PX_4,
  ),
  pageSubHeading: classNames(
    FontSize.TEXT_XL,
    TextAlign.TEXT_CENTER,
    FontWeight.FONT_BOLD,
    Padding.PY_4,
    Margin.MT_6,
  ),
  pageIntro: classNames(
    TextAlign.TEXT_CENTER,
    FontSize.TEXT_SM,
    LineHeight.LEADING_NORMAL,
    Margin.MB_6,
  ),
};

const SelectProductsPage: React.FunctionComponent<MinBedriftRouteComponentProps<{ agreementNumber: string }>> = ({ location, match }) => {
  const agreementNumber = +match.params.agreementNumber;
  const orderModel = new OrderSessionModel();

  const { data, error, loading } = useQuery<GetAgreementOrderInitial, GetAgreementOrderInitialVariables>(GET_AGREEMENT_ORDER_INITIAL, {
    variables: {
      agreementNumber
    },
    fetchPolicy: "cache-first"
  });
  const spidContext = useSpid();
  const {state: {givenName, familyName} = { givenName: '', familyName: '' }} = spidContext;
  const [chosenProducts, setChosenProduct] = useState(orderModel.products);
  const { role } = useRole();
  useEffect(() => {
    orderModel.products = chosenProducts;
  }, [chosenProducts]);

  if (loading) {
    return <LoadingFragment />;
  }

  if (error || !data || !data.agreement) {
    return <EmptyAgreements error={error} />;
  }

  const agreement = data.agreement;
  const { maxSubscriptionsPerEmployee } = agreement;
  const activeProducts: GetAgreementOrderInitial_agreement_products[] = (agreement.products || []).filter(p => p.active);
  const inactiveProducts: GetAgreementOrderInitial_agreement_products[] = (agreement.products || []).filter(p => !p.active);

  const maxSubscriptions = maxSubscriptionsPerEmployee || Infinity;
  const canProceed = chosenProducts.length > 0 && chosenProducts.length <= maxSubscriptions;
  const orderButtonText = chosenProducts.length > 0 ? `(${chosenProducts.length} abonnement) Gå videre` : 'Gå videre';

  const transformProduct = (products: Partial<Product>[]) => {
    setChosenProduct(products.map(s => ({ id: s.id!, productGroup: s.productGroup!, siebelProductId: s.siebelProductId || '' })))
  };

  const redirectToNextStep = () => {
    sessionStorage.setItem("orderFlowUrl",`/bestill/${agreementNumber}/flyt/produkter`);
    spidContext.actions.login({simplified: false, teaser: teaser()});
  };

  return (
    <Container className={styleClass.container}>
      <div className={classNames(BackgroundColor.BG_WHITE)}>

        <div className={classNames(MaxWidth.MAX_W_XL, Margin.MX_AUTO)}>
          <Heading level={HeadingLevel.FOUR} className={styleClass.pageHeading}>
            {`${agreement.accountName} har inngått en avtale med Schibsted som gjør at du kan få abonnement på utvalgte aviser.`}
          </Heading>
        </div>


        <div className={classNames(MaxWidth.MAX_W_XS,Margin.MX_AUTO,Padding.PT_4,Padding.PB_14, Padding.SM_PB_10)}>
          { spidContext.state.userId ? <UserBadge
            name={`${givenName} ${familyName}`}
            infotext={`produktene vil bli registrert på din Schibsted-konto`}
            role={role}
          /> : (
            <div
              tabIndex={0}
              role="button"
              onClick={redirectToNextStep}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  redirectToNextStep();
                }
              }}
              className={classNames(Display.FLEX, FlexDirection.FLEX_ROW, AlignItems.ITEMS_CENTER)}
            >
            <img src={"/svg/books.svg"} alt="Logg deg inn"/>
            <div>
              <Link
                className={classNames('invitationLink')}
              >Logg deg inn</Link> med din personlige Schibsted konto for å kunne registrere ditt bedriftsabonnement.
            </div>
          </div>
          )}
        </div>
      </div>

      <div className={classNames(MaxWidth.MAX_W_6XL,Margin.MX_AUTO)}>
        <Heading level={HeadingLevel.TWO} className={styleClass.pageSubHeading}>
          {maxSubscriptions === Infinity ? 'Velg produkter' : `Velg maks ${maxSubscriptions} produkt${maxSubscriptions > 1 ? 'er' : ''}`}
        </Heading>

      <ProductBoxList
        activeProducts={activeProducts}
        inactiveProducts={inactiveProducts}
        chosenProducts={chosenProducts}
        setChosenProduct={transformProduct}
        agreement={agreement}
      />

      { spidContext.state.userId ? <OrderButton
        text={orderButtonText}
        canProceed={canProceed}
        url={`/bestill/${agreementNumber}/flyt/brukerinformasjon${location.search}`}
      /> : <OrderButton
        text={'Logg inn for å velge produkter'}
        canProceed={true}
        onProceedClick={redirectToNextStep}
        canProceedBackgroundColor={BackgroundColor.BG_PRIMARY_1}
      />}
      </div>
    </Container>
  );
};

export default SelectProductsPage;
