import * as React from "react";
import {Location, UnregisterCallback} from "history";
import {Route, RouteComponentProps, withRouter} from "react-router";


interface IListeningRouteState<S> {
  location: Location<S>;
}

class ListeningRoute extends React.Component<RouteComponentProps & any, IListeningRouteState<any>> {
  //@ts-ignore
  private unlisten: UnregisterCallback;

  constructor(props: RouteComponentProps & any) {
    super(props);
    this.state = {
      location: props.location
    }
  }

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location: Location) => {
      this.setState({location});
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return <Route {...this.props} location={this.state.location}/>;
  }
}

export default withRouter(ListeningRoute);
