import * as React from 'react';
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react';
import {
  BackgroundColor,
  classNames,
  Dropdown,
  Grid,
  GridGutter,
  Heading,
  HeadingLevel,
  Margin,
  MaxWidth,
  Padding,
  TextAlign,
  Width
} from '@snoam/pinata';
import {Reference} from '../EmployeesAndSubscriptionsTable/References';
import {
  GetAgreementProducts_me_agreementsWithDetails_departments,
  GetAgreementProducts_me_agreementsWithDetails_settings
} from '../../__generated__/GetAgreementProducts';
import * as H from 'history';
import {OrderSessionModel, Product, Reference as OrderReference} from "../../pages/Order/models/OrderSessionModel";

const styleClass = {
  greyBox: classNames(
    Padding.PX_4,
    Padding.PB_4,
    BackgroundColor.BG_NEUTRAL_1
  ),
};

interface IReferences {
  heading: string;
  location: Pick<H.Location, 'search'>;
  history: Pick<H.History, 'push'>;
  formSubmitted: boolean;
  className?: string;
  index?: number;
  settings: GetAgreementProducts_me_agreementsWithDetails_settings;
  departments?: GetAgreementProducts_me_agreementsWithDetails_departments[]
  product: any;
  referencesPrProduct: boolean;
  setReferences: Dispatch<SetStateAction<Partial<OrderReference>[]>>;
  orderModel: OrderSessionModel;
  setSelectedDepartments: Dispatch<SetStateAction<string[]>>;
}

const References: React.FunctionComponent<IReferences> = ({className, heading, product: agreementProduct, referencesPrProduct, orderModel, settings, departments = [], setReferences, setSelectedDepartments, location}) => {
  const product = orderModel.getProductById(agreementProduct.id) as Product;

  const q: { [k: string]: string | undefined } = (location.search.replace(/^\?/, '').split('&').reduce((q, s) => {
    let [k, v] = s.split('=');
    return Object.assign(q, {[k]: v});
  }, {}));

  const [ref1Value, setRef1Value] = useState<string>(product.ref1 as string);
  const [ref2Value, setRef2Value] = useState<string>(product.ref2 as string);

  const reference1 = Object.assign({}, settings.reference1, {value: ref1Value});
  const reference2 = Object.assign({}, settings.reference2, {value: ref2Value});
  const [agreementDepartmentNumber, setDepartment] = useState<string | null>(`${product.department || (q && q.avdeling) || ''}`);

  const onChange = (set: Dispatch<SetStateAction<string>>) => (e: string | ChangeEvent<HTMLInputElement>) => {
    const val: string = typeof e === 'object' ? e.target.value : e;
    set(val);
  };
  const onDepartmentChange = (item: string) => {
    setDepartment(item);
  };

  useEffect(() => {
    if (!referencesPrProduct) {
      orderModel.products = orderModel.products.map(p => {
        if (ref1Value) {
          orderModel.updateReference({
            name: reference1.name || 'reference1',
            value: ref1Value,
            productId: p.id
          })
        } else {
          orderModel.deleteReference(p.id, reference1.name || 'reference1');
        }
        if (ref2Value) {
          orderModel.updateReference({
            name: reference2.name || 'reference2',
            value: ref2Value,
            productId: p.id
          })
        } else {
          orderModel.deleteReference(p.id, reference2.name || 'reference2');
        }

        if (ref1Value || ref2Value) {
          setReferences(orderModel.references);
        }

        return {
          ...p,
          ref1: ref1Value,
          ref2: ref2Value,
          department: agreementDepartmentNumber
        }
      });
    } else {
      orderModel.updateProductById(product.id, {
        ref1: ref1Value,
        ref2: ref2Value,
        department: agreementDepartmentNumber
      });

      if (ref1Value) {
        orderModel.updateReference({
          name: reference1.name || 'reference1',
          value: ref1Value,
          productId: product.id
        })
      } else {
        orderModel.deleteReference(product.id, reference1.name || 'reference1');
      }
      if (ref2Value) {
        orderModel.updateReference({
          name: reference2.name || 'reference2',
          value: ref2Value,
          productId: product.id
        })
      } else {
        orderModel.deleteReference(product.id, reference2.name || 'reference2');
      }

      if (ref1Value || ref2Value) {
        setReferences(orderModel.references);
      }
    }
    setSelectedDepartments(orderModel.products.map((p) => `${p.department || ''}`).filter(s => s));
  }, [ref1Value, ref2Value, agreementDepartmentNumber]);

  const selectedDepartment = departments.find(dep => dep.agreementNumber.toString() === agreementDepartmentNumber);

  return (
    <Grid className={classNames(styleClass.greyBox, className)} gutter={GridGutter.NONE}>
      <Heading level={HeadingLevel.THREE} className={classNames(Margin.MY_4, TextAlign.TEXT_CENTER)}>{heading}</Heading>
      {
        departments && departments.length && (
          <Dropdown
            disabled={!!q.avdeling}
            onChange={onDepartmentChange}
            hideLabel={true}
            className={MaxWidth.MAX_W_SM}
            data={departments.map(dep => ({
              name: dep.name!,
              value: dep.agreementNumber.toString()!,
            })).sort((a, b) => a.name < b.name ? -1 : 1)}
            defaultTriggerText={selectedDepartment && selectedDepartment.name ? selectedDepartment.name : `Velg avdeling`}
          />
        )
      }
      {reference1 &&
      <Reference
        className={classNames(Width.W_FULL)}
        onChange={onChange(setRef1Value)}
        reference={reference1}
        skipHeader={true}
      />
      }
      {reference2 &&
      <Reference
        className={classNames(Width.W_FULL, Margin.MT_4)}
        onChange={onChange(setRef2Value)}
        reference={reference2}
        skipHeader={true}
      />
      }
    </Grid>
  );
};

export default References;
