import {
  classNames,
  Display,
  FontWeight,
  Grid,
  GridGutter,
  GridItem,
  GridSpan,
  Icon,
  IconName, IconSize,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  FontSize,
  AlignItems,
  FlexWrap,
  Checkbox
} from '@snoam/pinata';
import * as React from 'react';
import Table, { ITableProps } from '../Table/Table';
import { IEmployee } from '../../models/EmployeeModel';
import AdministratorAddButton from './AdministratorAddButton';
import AdministratorRemoveButton from './AdministratorRemoveButton';
import SubscriptionsTable from './SubscriptionsTable';
import { GetAgreement_me_agreementsWithDetails } from '../../__generated__/GetAgreement';
import { AdminType } from "../../__generated__/globalTypes";
import AccessBoundary from "../Boundary/AccessBoundary";
import UserIcon from "./UserIcon";
import { useSelectionContext } from '../../context/SelectionContext/SelectionContext';
import { ResponsiveContext } from '../../context/ResponsiveContext/ResponsiveContext';
import { useContext } from 'react';
import {MaybeAdminType, useRole} from "../Boundary/RoleBoundary";

const styleClass = {
  employeesTable: classNames(
    'employeesTable',
    Margin.MB_8,
  ),
  name: classNames(
    FontWeight.FONT_SEMIBOLD,
    FontSize.TEXT_LG,
  ),
  numberOfActiveProducts: classNames(
    FontWeight.FONT_THIN,
    TextColor.TEXT_NEUTRAL_6,
  ),
  verticalAlign: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    FlexWrap.FLEX_WRAP,
  ),
  taxationDisclaimer: classNames(
    Margin.MT_2,
    Padding.PX_2,
    FontSize.SM_TEXT_SM,
  ),
};

type TableControlsProps = Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'children'>;

export const TableControls = ({ className = '', children }: TableControlsProps) => {
  return (
    <div
      className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER, FlexWrap.FLEX_WRAP, Padding.PY_0, 'buttons', className)}>
      {children}
    </div>
  )
};

interface EmployeesTableProps {
  agreement: GetAgreement_me_agreementsWithDetails;
  employees: IEmployee[];
}

function renderEmployee(employee: IEmployee, expanded: boolean, mobile: boolean, selectioncontext: any, userRole: MaybeAdminType) {

  const onCheckboxClick = (e: any, employee: any) => {
    employee.isSelected = !employee.isSelected;
    selectioncontext.context.actions.addOrRemoveFromSelection(employee)
    e.preventDefault();
  }
  const gridLayout = [GridSpan.XSMALL_12, GridSpan.SMALL_6, GridSpan.MEDIUM_6, GridSpan.LARGE_6]
  return (
    <Grid gutter={GridGutter.NONE}>
      {mobile ?
        <GridItem span={gridLayout}>
          <div className={styleClass.verticalAlign}>
            <div>
              {userRole !== AdminType.SUPEROBSERVER &&
                <Checkbox
                  onClick={(e) => onCheckboxClick(e, employee)}
                  checked={employee.isSelected}
                />
              }
            </div>
            <UserIcon admin={employee.admin} />
            <div className={styleClass.name}>{employee.name}
              <div className={styleClass.numberOfActiveProducts}>
                {`${employee.activeSubscriptions.length} ${employee.activeSubscriptions.length > 1 ? 'Aktive produkter' : 'Aktivt produkt'}`}
              </div>
            </div>
          </div>
        </GridItem>
        : <GridItem span={gridLayout}>
          <div className={styleClass.verticalAlign}>
            <div>
              {userRole !== AdminType.SUPEROBSERVER &&
                <Checkbox
                  onClick={(e) => onCheckboxClick(e, employee)}
                  checked={employee.isSelected}
                />
              }
            </div>
            <UserIcon admin={employee.admin} selected={expanded} />
            <div className={styleClass.name}>{employee.name}</div>
          </div>
        </GridItem>}
      {(!expanded && !mobile) &&
        <GridItem span={gridLayout}>
          <div className={styleClass.verticalAlign}>
            <Icon
              name={IconName.BEDRIFT_EDIT_PRODUCTS}
              className={classNames(Margin.MT_1, Margin.MR_2, TextColor.TEXT_NEUTRAL_6,)}
              size={IconSize.MEDIUM}
              href={`${process.env.PUBLIC_URL}/svg/products.svg`} />
            <div className={styleClass.numberOfActiveProducts}>
              {employee.activeSubscriptions.length} Aktive produkter
          </div>
          </div>
        </GridItem>
      }
    </Grid>
  );
}

function renderEmployeeExpanded(employee: IEmployee, agreement: GetAgreement_me_agreementsWithDetails) {
  const employeeWithSpid = employee.subscriptions.find(s => s.spid !== null);

  return (
    <Grid gutter={GridGutter.NONE}>
      <Grid isNested={true} className={classNames(Margin.MB_2, Padding.PX_2)}>
        <div>{employee.activeSubscriptions.length} Aktive produkter</div>
        <div className={TextAlign.TEXT_RIGHT}>Kundenummer: {employee.customerNumber}</div>
      </Grid>
      <SubscriptionsTable
        agreement={agreement}
        employee={employee}
        subscriptions={employee.activeSubscriptions}
      />
      <AccessBoundary role={[AdminType.SUPERADMIN, AdminType.ADMIN]}>
        {!employee.admin && employeeWithSpid && employeeWithSpid.spid &&
          <AdministratorAddButton
            spid={employeeWithSpid.spid}
            agreementNumber={agreement.agreementNumber}
            triggerTooltip={() => void (0)}
          />
        }
        {employee.admin && employeeWithSpid && employeeWithSpid.spid &&
          <AdministratorRemoveButton
            spid={employeeWithSpid.spid}
            agreementAdmins={agreement.admins}
            agreementNumber={agreement.agreementNumber}
            triggerTooltip={() => void (0)}
          />
        }
      </AccessBoundary>
      { // We do not want to remove until we know the markets does not want this feature
        /*<Grid className={styleClass.taxationDisclaimer}>
        <div className={classNames(Display.SM_FLEX, Display.XS_HIDDEN)}>* Beløpet er estimert basert på antatt markedsverdi, det vil si standardpriser i sluttbrukermarkedet på tidspunktet for uttrekket. Disse prisene kan avvike fra avtalt pris per abonnement i henhold til bedriftsavtalen. Eventuelle prisendringer i løpet av perioden er ikke hensyntatt. </div>
      </Grid>*/}
    </Grid>
  );
}

const EmployeesTable: React.FunctionComponent<EmployeesTableProps> = ({ agreement, employees }) => {
  const selectioncontext = useSelectionContext()
  const { mobile } = useContext(ResponsiveContext);
  const {role: userRole} = useRole();

  const tableProps: ITableProps<IEmployee> = {
    rows: employees,
    alternatingColor: true,
    canExpand: () => true,
    enablePagination: true,
    rowRenderer: (employee: IEmployee, expanded: boolean) => renderEmployee(employee, expanded, mobile, selectioncontext, userRole),
    expandedRenderer: (employee: IEmployee) => renderEmployeeExpanded(employee, agreement),
  };

  return (
    <div className={styleClass.employeesTable}>
      <Table {...tableProps} />
    </div>
  );
};

export default EmployeesTable;
