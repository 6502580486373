import {
  BackgroundColor,
  Button,
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Height,
  Icon,
  LineHeight,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  FlexDirection,
  AlignContent,
  ButtonSkin,
  IconName,
  IconColor,
} from '@snoam/pinata';
import {Modal, ModalContext} from "@snoam/mono-modal";
import * as React from 'react';
import {useContext, useState} from 'react';
import {SingleCalendar} from "@snoam/mono-singlecalendar";
import {Moment} from "moment";
import {ISubscription} from "../../models/EmployeeModel";
import {
  ApolloError,
  DefaultContext,
  InMemoryCache,
  MutationFunction,
  MutationUpdaterFunction,
  useMutation
} from "@apollo/client";
import {TerminateSubscription, TerminateSubscriptionVariables} from "../../__generated__/TerminateSubscription";
import {checkNoErrors, TERMINATE_SUBSCRIPTION} from "../../mutations";
import {GET_AGREEMENT} from "../../queries";
import {
  GetAgreement,
  GetAgreement_me_agreementsWithDetails_subscriptions,
  GetAgreementVariables
} from "../../__generated__/GetAgreement";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignContent.CONTENT_CENTER,
    Padding.PX_1,
    Padding.MD_PX_10,
    Height.H_FULL,
  ),
  heading: classNames(
    FontSize.TEXT_2XL,
    FontWeight.FONT_HAIRLINE,
    TextAlign.TEXT_CENTER,
    Margin.MB_6,
  ),
  magazine: {
    heading: classNames(
      FontWeight.FONT_BOLD,
      FontSize.TEXT_BASE,
    ),
  },
  infoText: classNames(
    Margin.MB_6,
    TextAlign.TEXT_CENTER,
    LineHeight.LEADING_NORMAL,
  ),
  formWrapper: classNames(
    Width.LG_W_3_4,
    Margin.MX_AUTO,
    'minbedrift-terminate-sub-form',
  ),
  terminateButton: classNames(
    Margin.MY_6,
    Width.W_FULL,
    FontWeight.FONT_NORMAL,
    Padding.PX_3,
  ),
  closeButton: classNames(
    classNames(
      Margin.MT_8,
      Margin.MB_1,
      BackgroundColor.BG_TRANSPARENT,
      BackgroundColor.HOVER_BG_TRANSPARENT,
      TextColor.TEXT_NEUTRAL_6,
      FontWeight.FONT_HAIRLINE
    )
  ),
  smallInfoText: classNames(
    FontSize.MD_TEXT_SM,
    Margin.MB_6,
    TextAlign.TEXT_CENTER,
    LineHeight.LEADING_NORMAL,
  ),
  errorBox: classNames(
    Margin.MB_2,
    Padding.P_2,
    BackgroundColor.BG_NEGATIVE,
    TextColor.TEXT_WHITE
  )
};

interface ITerminateSubscriptionModalProps {
  id: string;
  onClose?: (evt: any) => void;
  className?: string;
  subscription: ISubscription;
  agreementNumber: number;
}

interface TerminateSubscriptionBtnProps {
  agreementNumber: number;
  assetNumber: string;
  stopEdition: string;
  stopDate: Moment | undefined;
  canTerminateSubscription: boolean;
}

const TerminateSubscriptionBtn: React.FunctionComponent<TerminateSubscriptionBtnProps> = ({agreementNumber, assetNumber, stopEdition, stopDate, canTerminateSubscription}) => {
  const {closeModal} = useContext(ModalContext);

  const terminateSubscriptionMutation: MutationFunction<TerminateSubscription, TerminateSubscriptionVariables, DefaultContext, InMemoryCache> = useMutation<TerminateSubscription, TerminateSubscriptionVariables>(TERMINATE_SUBSCRIPTION)[0];
  const [isSavingChange, setIsSavingChange] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const update: MutationUpdaterFunction<TerminateSubscription, TerminateSubscriptionVariables, DefaultContext, InMemoryCache> = (proxy, mutationResult) => {
    const newSubscriptionItem = mutationResult.data && mutationResult.data.terminateSubscription && mutationResult.data.terminateSubscription.subscriptionItem;
    if (newSubscriptionItem) {
      const variables = {
        agreementNumber: [agreementNumber],
        shouldFetchSubscriptions: true,
      };
      const data = proxy.readQuery<GetAgreement, GetAgreementVariables>({query: GET_AGREEMENT, variables});
      if (data && data.me && data.me.agreementsWithDetails && data.me.agreementsWithDetails.length === 1) {
        const subscriptions: GetAgreement_me_agreementsWithDetails_subscriptions[] = data.me.agreementsWithDetails[0].subscriptions;
        const subscription: undefined | GetAgreement_me_agreementsWithDetails_subscriptions = subscriptions.find(s => s.subscriptionItems.find(si => si.assetNumber === assetNumber));
        if (subscription) {
          const subscriptionItem = subscription && subscription.subscriptionItems.find(si => si.assetNumber === assetNumber);
          if (subscriptionItem) {
            Object.assign(subscriptionItem, newSubscriptionItem);
          }
        }
        proxy.writeQuery({query: GET_AGREEMENT, variables, data});
      }
    }
  };

  const terminateAction = () => {
    setIsSavingChange(true);
    setErrorMessage("");
    if (canTerminateSubscription && stopDate) {
      const formattedStopDate = stopDate.format('MM/DD/YYYY');
      terminateSubscriptionMutation({
        update,
        variables: {
          agreementNumber,
          assetNumber,
          stopEdition,
          stopDate: `${formattedStopDate}`,
        }
      }).then(checkNoErrors).then(() => {
        setIsSavingChange(false);
        setIsSaved(true);
        setTimeout(closeModal, 750);
      }).catch((err) => {
        if (err instanceof ApolloError) {
          const errMsg = err.message.replace('GraphQL error: Unexpected error value: ', '').replace(/^"(.*)"$/, '$1');
          setErrorMessage(errMsg);
        } else {
          setErrorMessage("Noe gikk galt, prøv gjerne igjen senere.");
        }
        setIsSavingChange(false);

      });
    }
  };

  return (
    <>
      <Button
        disabled={!canTerminateSubscription}
        text={isSaved ? 'Avsluttet' : 'Avslutt abonnement'}
        ariaLabel={isSaved ? 'Avsluttet' : 'Avslutt abonnement'}
        skin={isSaved ? ButtonSkin.CONFIRM : ButtonSkin.PRIMARY}
        iconRight={isSaved ? <Icon name={IconName.CHECK} color={IconColor.PRIMARY_LIGHT}/> : undefined}
        className={styleClass.terminateButton}
        onClick={terminateAction}
        loading={isSavingChange}
        loadingText="Avslutter..."
        style={{transition: 'background-color 500ms ease'}}
      />
      {errorMessage &&
			<div className={styleClass.errorBox}>
        {errorMessage}
			</div>
      }
    </>
  )
};

const TerminateSubscriptionModal: React.FunctionComponent<ITerminateSubscriptionModalProps> = ({id, subscription, agreementNumber}) => {

  const {assetNumber} = subscription;
  const {closeModal} = useContext(ModalContext);
  const [date, setDate] = useState<Moment>();

  const onDateChange = (date: Moment) => {
    setDate(date);
  };

  const renderContent = () => {
    return (
      <>
        <p className={styleClass.infoText}>Abonnenten får ikke lenger tilgang til dette produktet via bedriftsavtalen.
          Velg dato for avslutning:</p>

        <div className={styleClass.formWrapper}>

          <SingleCalendar
            inclusivelyAfterDays={1}
            startDatePlaceholderText="Velg dato"
            onDateChange={onDateChange}
            date={date}
            renderCalendarInfo={<span className={classNames(FontWeight.FONT_BOLD)}>Velg dato når endringen skal tre i kraft</span>}
          />

          <TerminateSubscriptionBtn
            assetNumber={assetNumber}
            stopEdition={''}
            stopDate={date}
            canTerminateSubscription={date !== undefined && date !== null}
            agreementNumber={agreementNumber}
          />
          <p className={styleClass.smallInfoText}>
            Produkttilgangen vil stoppes på første mulige dato etter valgt stoppdato. Det kan bli noe variasjon i
            stoppdato på produkter med fysisk levering.
          </p>
        </div>
      </>
    )

  };

  return (
    <Modal
      id={id}
      backdrop={true}
      className={BackgroundColor.BG_NEUTRAL_1}
      onClose={closeModal}
    >
      <div className={styleClass.root}>
        <Heading
          level={HeadingLevel.TWO}
          className={styleClass.heading}>
          Avslutt abonnement for {subscription.name}
        </Heading>

        {renderContent()}

        <Button
          onClick={closeModal}
          text={'Avbryt'}
          ariaLabel={'Avbryt'}
          className={styleClass.closeButton}
        />
      </div>
    </Modal>
  )
};

export default TerminateSubscriptionModal;
