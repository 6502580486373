import * as React from 'react';
import { MinBedriftRouteComponentProps } from "../../routes/utils";
import {
  GetAgreementOrderInitial, GetAgreementOrderInitial_agreement_settings,
  GetAgreementOrderInitialVariables
} from "../../__generated__/GetAgreementOrderInitial";
import { useQuery } from "@apollo/client";
import { GET_AGREEMENT_ORDER_INITIAL } from "../../queries";
import { LoadingFragment } from "../LoadingPage";
import { EmptyAgreements } from "../Agreements/EmptyAgreements";
import OrderButton from "../../components/OrderButton/OrderButton";
import {
  AlignItems,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  Margin,
  Padding,
  TextAlign,
  Width,
} from "@snoam/pinata";
import SvgImage from "../../components/SvgImage/SvgImage";
import TermsAndConditions from "../../components/TermsAndConditions";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextAlign.TEXT_CENTER,
    AlignItems.ITEMS_CENTER,
    Margin.MD_MT_8,
    Margin.LG_MT_16,
    Padding.PX_4,
    Padding.PB_10,
    Margin.MB_8,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.MD_TEXT_2XL,
    FontSize.TEXT_LG,
    Margin.LG_MT_8,
    Margin.MB_6,
  ),
  text: classNames(
    FontSize.MD_TEXT_LG,
    FontSize.TEXT_SM,
    FontWeight.FONT_LIGHT,
  ),
};

export interface Props extends MinBedriftRouteComponentProps<{ agreementNumber: string }> {

}

const OpenidConnectToExternalLoginPage: React.FunctionComponent<Props> = ({ match }) => {

  const agreementNumber = +match.params.agreementNumber;
  const variables: GetAgreementOrderInitialVariables = { agreementNumber };
  const { data, error, loading } = useQuery<GetAgreementOrderInitial, GetAgreementOrderInitialVariables>(GET_AGREEMENT_ORDER_INITIAL, {
    variables,
    fetchPolicy: "cache-first"
  });

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error || !data || !data.agreement) {
    return <EmptyAgreements error={ error }/>;
  }

  const agreement = data.agreement;
  const settings: GetAgreementOrderInitial_agreement_settings = agreement.settings;
  const {
    openidConnectAuthorizationEndpoint,
    openidConnectClientId,
    openidConnectScope,
    openidConnectSystemName,
  } = settings;

  const redirectUri = window.location.href.replace(/\/til-ekstern-login$/, "/fra-ekstern-login");
  const params: { [k: string]: string } = {
    client_id: openidConnectClientId || '',
    redirect_uri: redirectUri,
    state: 'a',
    scope: openidConnectScope || 'openid profile',
    response_type: 'code',
  };
  const urlParams = Object.keys(params).reduce((a, e) => a.concat(`${e}=${encodeURIComponent(params[e])}`), [] as string[]);
  const authUrl = `${ openidConnectAuthorizationEndpoint }?${urlParams.join("&")}`;

  return (
    <div className={ styleClass.root }>
      <SvgImage
        href={`${process.env.PUBLIC_URL}/svg/schibsted-love-privacy.svg`}
        svgStyle={{width: '100%', height: '200px'}}
        className={classNames(Width.W_FULL, Display.FLEX, Margin.MX_AUTO, Margin.MT_NEGATIVE_6)}
      />
      <Heading level={HeadingLevel.THREE} className={styleClass.heading}>
        For å verifisere din tilknytning til {agreement.accountName} må du være logget inn med {openidConnectSystemName}.
      </Heading>
      <p className={styleClass.text}>
        Dersom du ikke allerede er logget inn vil du bli sendt til {openidConnectSystemName} ved å trykke "Fullfør bestilling".
      </p>
      <TermsAndConditions/>
      <OrderButton
        canProceed={ true }
        url={authUrl}
        absoluteUrl={true}
        text={ "Fullfør bestilling" }
        backBtnUrl={ `/bestill/${ agreement.agreementNumber }/flyt/brukerinformasjon${ window.location.search }` }
      />
    </div>
  );
};

export default OpenidConnectToExternalLoginPage;
