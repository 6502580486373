import React, { useContext } from "react";
import LoginAndLogoutButton from "../LoginAndLogoutButton/LoginAndLogoutButton";
import {
  IWithSpidContext, useSpid
} from "@snoam/mono-spid";
import {
  classNames,
  Margin,
  Padding,
  TextColor,
  BackgroundColor,
  TextDecoration,
  MaxWidth
} from "@snoam/pinata";
import { useQuery } from "@apollo/client";
import { GetRole, GetRoleVariables } from "../../__generated__/GetRole";
import { GET_ROLE } from "../../queries";
import { AdminType } from "../../__generated__/globalTypes";
import UserBadge from "../UserBadge/UserBadge";
import { ResponsiveContext } from "../../context/ResponsiveContext/ResponsiveContext";

const styleClass = {
  userBadge: classNames(
    Padding.PX_0,
    Margin.MT_10,
    Margin.ML_NEGATIVE_3,
    TextColor.TEXT_WHITE
  ),
  loginLogoutButton: (mobile: boolean) =>
    classNames(
      {
        [BackgroundColor.BG_TRANSPARENT]: !mobile,
        [BackgroundColor.BG_WHITE]: mobile,
        [TextColor.TEXT_WHITE]: !mobile,
        [TextColor.HOVER_TEXT_BLACK]: !mobile
      },
      Margin.MT_0,
      Margin.MB_16,
      Margin.LG_MB_0,
      MaxWidth.MAX_W_LG
    ),
  deliveredByParagraph: classNames(Margin.MT_4),
  dataPolicyParagraph: classNames(
    Margin.MT_AUTO,
    Padding.PT_6,
    TextDecoration.UNDERLINE
  )
};

interface IFooterProfileProps {
  agreementNumber: string;
  history: any;
  dataPolicyUrl: string;
}

const FooterUserBadge = (
  props: IWithSpidContext & {
    email: string | null;
    role: AdminType | null;
    transparent?: boolean;
    className?: string;
    textColorNegative?: boolean;
  }
) => {
  const { email, role, spidContext, ...otherProps } = props;

  if (!spidContext.state.userId) {
    return null;
  }

  return (
    <UserBadge
      classNames={styleClass.userBadge}
      email={email}
      name={spidContext.state.givenName || ''}
      role={role}
      transparent={true}
      {...otherProps}
    />
  );
};

const FooterProfile: React.FC<IFooterProfileProps> = ({
  agreementNumber,
  history,
  dataPolicyUrl
}) => {
  const { mobile, portrait } = useContext(ResponsiveContext);
  const variables = {
    agreementNumber: agreementNumber ? +agreementNumber : undefined
  };
  const { data, error, loading } = useQuery<GetRole, GetRoleVariables>(
    GET_ROLE,
    { variables }
  );
  const { email = null, role = null } =
    loading || error ? {} : data ? data.me : {};
  const spidContext = useSpid();

  return (
    <>
      <FooterUserBadge
        email={email}
        role={role}
        spidContext={spidContext}
        textColorNegative={true}
      />
      <LoginAndLogoutButton
        spidContext={spidContext}
        agreementNumber={agreementNumber}
        history={history}
        className={styleClass.loginLogoutButton(mobile)}
      />
      {portrait && (
        <>
          <p className={styleClass.deliveredByParagraph}>
            Denne tjenesten er levert av Schibsted Media AS
          </p>
          <p className={styleClass.dataPolicyParagraph}>
            <a href={dataPolicyUrl}>Vilkår og personvernserklæring</a>
          </p>
        </>
      )}
    </>
  );
};

export default FooterProfile;
