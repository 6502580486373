import React, {useContext, useEffect, useState} from "react";
import {useRole} from "../../components/Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";
import {Redirect} from "react-router";
import {useQuery} from "@apollo/client";
import {
  GetAgreements,
  GetAgreements_me_agreements_edges,
  GetAgreementsVariables
} from "../../__generated__/GetAgreements";
import {GET_AGREEMENTS} from "../../queries";
import {Grid, GridComposition, GridItem, Heading, HeadingLevel, Padding,} from "@snoam/pinata";
import {LoadingFragment} from "../LoadingPage";
import ActivationDegreeWidget from "./ActivationDegreeWidget";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import AverageActivationDegreeWidget from "./AverageActivationDegreeWidget";
import NewestAgreements from "./NewestAgreements";
import TableauReports from "./TableauReports";
import moment from "moment";
import SendMessage from "./SendMessage";
import ActiveMessages from "./ActiveMessages";
import {isSuperOrObserver} from "../../utils";

const Dashboard: React.FunctionComponent = () => {
  const {role: userRole} = useRole();
  const [agreementsWithActivationDegree, setAgreementsWithActivationDegree] = useState<GetAgreements_me_agreements_edges[]>([]);
  const [agreements, setAgreements] = useState<GetAgreements_me_agreements_edges[]>([]);
  const {mobile, tablet} = useContext(ResponsiveContext);

  const {
    data,
    error,
    loading
  } = useQuery<GetAgreements, GetAgreementsVariables>(GET_AGREEMENTS, {});

  if (error) {
    throw error;
  }

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (data && data.hasOwnProperty('me')) {
      const {agreements} = data.me;
      const agreementsWithActivationDegree = agreements.edges.filter(a =>
        a.activationDegree !== null && a.settings.activationPotential !== null
      );
      setAgreementsWithActivationDegree(agreementsWithActivationDegree);
      setAgreements(agreements.edges);
    }

  }, [data, loading]);

  if(userRole && !isSuperOrObserver(userRole)) {
    return <Redirect to={'/admin/avtaler'} from={'/admin/dashboard'} />
  }

  if(loading) {
    return <LoadingFragment />;
  }

  const lowToHigh = [...agreementsWithActivationDegree]
    .sort((a, b) => a.activationDegree! < b.activationDegree! ? -1 : 1);
  const highToLow = [...agreementsWithActivationDegree]
    .sort((a, b) => a.activationDegree! > b.activationDegree! ? -1 : 1);
  const newestAgreements = [...agreements]
    .sort((a, b) => moment(a.created, 'MM/DD/YYYY') > moment(b.created, "MM/DD/YYYY") ? -1 : 1).slice(0, 10);

  return (
    <>
      <Heading level={HeadingLevel.ONE} className={Padding.PB_4}>Dashboard</Heading>
      <Grid composition={(mobile || tablet) ? GridComposition.DEFAULT : GridComposition.BRAVO} className={Padding.PL_0}>
        <GridItem>
          <ActivationDegreeWidget
            key={`widget-${lowToHigh}`}
            filteredAgreements={lowToHigh}
            heading={"Lavest Aktiveringsscore"}
            defaultPotential={2}
          />
        </GridItem>
        <GridItem>
          <ActivationDegreeWidget
            key={`widget-${highToLow}`}
            filteredAgreements={highToLow}
            heading={"Høyest Aktiveringsscore"}
          />
        </GridItem>
      </Grid>
      <Grid composition={(mobile || tablet) ? GridComposition.DEFAULT : GridComposition.ALPHA} className={Padding.PL_0}>
        <GridItem>
          <AverageActivationDegreeWidget
            agreements={agreementsWithActivationDegree}
          />
        </GridItem>
        <GridItem>
          <NewestAgreements
            agreements={newestAgreements}
          />
        </GridItem>
        <GridItem>
          <TableauReports/>
        </GridItem>
      </Grid>
      <Grid composition={GridComposition.DEFAULT} className={Padding.PL_0}>
        {userRole === AdminType.SUPERADMIN &&
          <GridItem>
            <SendMessage />
          </GridItem>
        }
        <GridItem>
          <ActiveMessages />
        </GridItem>
      </Grid>
    </>
  )
};

export default Dashboard;
