import * as React from "react";
import RoleBoundary, {RoleBoundaryState} from "./RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";
import {MinBedriftRouteComponentProps} from "../../routes/utils";

const RBAC: React.FunctionComponent<{ role: AdminType | AdminType[] }> = ({children, role, ...otherProps}) => (
  <RoleBoundary
    {...otherProps as MinBedriftRouteComponentProps<{ agreementNumber: string }>}
    role={role}
  >
    {
      ({roleBoundaryState}) => {

        switch (roleBoundaryState) {
          case RoleBoundaryState.HAS_ACCESS:
            return children;
          default:
            return null;
        }
      }
    }
  </RoleBoundary>
);

export const RbacSuperAdminOnly: React.FunctionComponent = ({children}) => (
  <RBAC
    children={children}
    role={AdminType.SUPERADMIN}/>
);

export const RbacSuperAdminOrAdminOrAccountant: React.FunctionComponent = ({children}) => (
  <RBAC
    children={children}
    role={[AdminType.SUPERADMIN, AdminType.ADMIN, AdminType.ACCOUNTANT]}/>
);

export const RbacSuperAdminOrAdmin: React.FunctionComponent = ({children}) => (
  <RBAC
    children={children}
    role={[AdminType.SUPERADMIN, AdminType.ADMIN]}/>
);
