import React, { useEffect } from 'react';
import { IWithSpidContext } from '@snoam/mono-spid';
import { teaser } from '../../utils';
import { History } from 'history';
import {Button, ButtonSize, ButtonSkin} from '@snoam/pinata';

const LoginAndLogoutButton = (props: IWithSpidContext & { className?: string; agreementNumber?: string, history: History }) => {
  const { spidContext, className, agreementNumber, history } = props;
  const isUserLoggedIn = spidContext.state.userId;

  useEffect(() => {
    const agreementNumber = getAgreementNumber();

    if (isNotOrderFlow() && agreementNumber) {
      history.push(`/bestill/${agreementNumber}`);
    }
    removeAgreementNumber();
  }, []);

  const isNotOrderFlow = () => {
    return !history.location.pathname.includes('/bestill');
  };

  const isNotAdminFlow = () => {
    return !history.location.pathname.includes('/admin')
  };

  const getAgreementNumber = () => {
    return sessionStorage.getItem('agreementNumber');
  };

  const setAgreementNumber = () => {
    if (agreementNumber && isNotAdminFlow()) {
      sessionStorage.setItem('agreementNumber', agreementNumber);
    }
  };

  const removeAgreementNumber = () => {
    sessionStorage.removeItem('agreementNumber');
  };

  const removeOrderFlowUrl = () => {
    sessionStorage.removeItem("orderFlowUrl");
  };

  const logout = () => {
    setAgreementNumber();
    spidContext.actions.logout();
  };

  const login = () => {
    removeOrderFlowUrl();
    setAgreementNumber();
    spidContext.actions.login({ simplified: false, teaser: teaser() });
  };

  return (
    <Button
      skin={ButtonSkin.SECONDARY}
      size={ButtonSize.SMALL}
      text={isUserLoggedIn ? 'Logg ut' : 'Logg inn'}
      ariaLabel={isUserLoggedIn ? 'Logg ut' : 'Logg inn'}
      className={className}
      onClick={isUserLoggedIn ? logout : login}
    />
  );
};

export default LoginAndLogoutButton;
