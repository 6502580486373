import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  Card,
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Icon,
  Margin,
  Padding,
  RadioButton,
  RadioButtonGroup,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  FlexDirection,
  AlignContent,
  TextTransform,
  AlignItems,
  ButtonSkin,
  IconName,
  IconColor
} from '@snoam/pinata';
import {Modal, ModalContext} from "@snoam/mono-modal";
import * as React from 'react';
import {useContext, useState} from 'react';
import {IAdminRole} from '../../models/AdminModel';
import {MutationFunction, useMutation, useQuery} from "@apollo/client";
import {GET_ADMIN_SEARCH_BY_EMAIL} from "../../queries";
import {FindAdmin, FindAdmin_findByEmail, FindAdminVariables} from "../../__generated__/FindAdmin";
import {checkNoErrors, UPDATE_ADMIN_ROLE} from "../../mutations";
import {UpdateAdminRole, UpdateAdminRoleVariables} from "../../__generated__/UpdateAdminRole";
import {LoadingFragment} from "../../pages/LoadingPage";
import {AdminType} from "../../__generated__/globalTypes";

const styleClass = {
  modal: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      AlignContent.CONTENT_CENTER,
      Padding.PX_1,
      Padding.MD_PX_10
    ),
    heading: classNames(
      FontSize.TEXT_2XL,
      FontWeight.FONT_HAIRLINE,
      TextAlign.TEXT_CENTER,
      Margin.MB_6
    ),
    adminName: classNames(
      TextTransform.CAPITALIZE,
    ),
    adminSpid: classNames(
      Display.BLOCK,
      Margin.MT_1,
    ),
    updateButton: {
      root: classNames(
        Margin.MY_6,
        Width.MD_W_4_5,
        Width.W_FULL,
        FontWeight.FONT_NORMAL,
        Padding.PX_3,
      ),
      wrapper: classNames(
        Display.FLEX,
        FlexDirection.FLEX_COL,
        AlignItems.ITEMS_CENTER
      )
    },
    text: classNames(
      Display.BLOCK,
      FontSize.TEXT_SM,
      Margin.MT_2,
      Margin.MB_6
    ),
    closeButton: classNames(
      classNames(
        Margin.MT_8,
        Margin.MB_1,
        BackgroundColor.BG_TRANSPARENT,
        BackgroundColor.HOVER_BG_TRANSPARENT,
        TextColor.TEXT_NEUTRAL_6,
        FontWeight.FONT_HAIRLINE
      )
    )
  },
};

interface IChangeAccessModalProps {
  id: string;
  roles: IAdminRole[];
  currentRole: AdminType;
  agreementNumber: number;
  adminEmail: string | null;
}

interface UpdateAdminBtnProps {
  searchResult: FindAdmin_findByEmail;
  role: IAdminRole
  agreementNumber: number;
}

const UpdateAdminBtn: React.FunctionComponent<UpdateAdminBtnProps> = ({searchResult, role, agreementNumber}) => {
  const {closeModal} = useContext(ModalContext);
  const updateAdminMutation: MutationFunction<UpdateAdminRole, UpdateAdminRoleVariables> = useMutation<UpdateAdminRole, UpdateAdminRoleVariables>(UPDATE_ADMIN_ROLE)[0];
  const [isSavingChange, setIsSavingChange] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const adminType = role.id;
  const spid = +searchResult.id;

  const updateAction = () => {
    setIsSavingChange(true);

    updateAdminMutation({
      variables: {
        agreementNumber,
        adminType,
        spid
      }
    }).then(checkNoErrors).then(() => {
      setIsSavingChange(false);
      setIsSaved(true);
      setTimeout(closeModal, 750);
    })
  };

  return (
    <Button
      disabled={!searchResult.id || !role.id}
      text={isSaved ? 'Endret' : 'Endre tilgang'}
      ariaLabel={isSaved ? 'Endret' : 'Endre tilgang'}
      skin={isSaved ? ButtonSkin.CONFIRM : ButtonSkin.PRIMARY}
      iconRight={isSaved ? <Icon name={IconName.CHECK} color={IconColor.PRIMARY_LIGHT} /> : undefined}
      className={styleClass.modal.updateButton.root}
      onClick={updateAction}
      loading={isSavingChange}
      loadingText="Endrer..."
      style={{transition: 'background-color 500ms ease'}}
    />
  )
};

const SearchResult: React.FunctionComponent<{ email: string, onResult: (result: FindAdmin_findByEmail) => void }> = ({email, onResult}) => {
  let {data, error, loading} = useQuery<FindAdmin, FindAdminVariables>(GET_ADMIN_SEARCH_BY_EMAIL, {
    variables: {email},
    fetchPolicy: "cache-first"
  });
  //@ts-ignore
  const {findByEmail = {}} = data;

  if (loading) {
    return (
      <Card className={classNames(Width.W_FULL, TextAlign.TEXT_CENTER)}>
        <LoadingFragment style={{}} />
      </Card>
    )
  }

  if (Object.keys(findByEmail).length === 0) {
    return null;
  }

  onResult(findByEmail);

  const {name, userId} = findByEmail;

  return (
    <Card className={classNames(Width.W_FULL, TextAlign.TEXT_CENTER)}>
      <span className={styleClass.modal.adminName}>{`${name!.formatted}`}</span>
      <span className={styleClass.modal.adminSpid}>{`Schibsted account nr.: ${userId}`}</span>
      {error ? <pre>{JSON.stringify(error, null, 2)}</pre> : null}
    </Card>
  )
};

const AdministrationModalSearchByEmail: React.FunctionComponent<{ onResult: (result: FindAdmin_findByEmail) => void; adminEmail: string | null}> = ({onResult, adminEmail}) => {

  if(adminEmail === null) {
    return (
      <p>Handlingen kan ikke gjennomføres fordi email-adresse mangler.</p>
    )
  }

  return (
    <SearchResult email={adminEmail} onResult={onResult} />
  );
};

const ChangeAccessModal: React.FunctionComponent<IChangeAccessModalProps> = ({id, roles, currentRole, agreementNumber, adminEmail}) => {

  const [adminResult, setAdminResult] = useState<FindAdmin_findByEmail>({} as FindAdmin_findByEmail);
  const [defaultRole] = roles;
  const [role, setRole] = useState(defaultRole);
  const {closeModal} = useContext(ModalContext);

  return (
    <Modal
      id={id}
      backdrop={true}
      className={BackgroundColor.BG_NEUTRAL_1}
      onClose={closeModal}
    >
      <div className={styleClass.modal.root}>
        <Heading
          level={HeadingLevel.TWO}
          className={styleClass.modal.heading}>
          Endre tilgang
        </Heading>

        <AdministrationModalSearchByEmail adminEmail={adminEmail} onResult={adminResult => setAdminResult(adminResult)}/>

        <RadioButtonGroup
          name={'rights'}
          value={currentRole}
        >
          {
            roles.map((role, i: number) => {
              return (
                <div key={`${role.id}_${i}`}
                     className={
                       classNames(
                         BorderStyle.BORDER_SOLID,
                         BorderWidth.BORDER_B_1,
                         BorderColor.BORDER_NEUTRAL_3,
                         FontWeight.FONT_BOLD
                       )
                     }>
                  <RadioButton
                    // skin={RadioButton.Skin.PRIMARY_3}
                    className={FlexDirection.FLEX_COL}
                    value={role.id}
                    text={role.label}
                    onClick={() => setRole(role)}
                  />
                </div>
              )
            })
          }
        </RadioButtonGroup>

        <div className={styleClass.modal.updateButton.wrapper}>
          <UpdateAdminBtn role={role} searchResult={adminResult} agreementNumber={agreementNumber} />
          <span className={classNames(FontSize.TEXT_SM, TextAlign.TEXT_CENTER)}>
            Administrator har lov til å gjøre endringer i avtalen
          </span>
        </div>

        <Button
          onClick={closeModal}
          text={'Avbryt'}
          ariaLabel={'Avbryt'}
          className={styleClass.modal.closeButton}
        />
      </div>
    </Modal>
  )
};

export default ChangeAccessModal;
