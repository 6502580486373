import * as React from 'react';
import {MinBedriftRouteComponentProps} from "../../utils";
import {useContext} from "react";
import {useQuery} from "@apollo/client";
import {
  GetAgreementOrderInitial,
  GetAgreementOrderInitialVariables
} from "../../../__generated__/GetAgreementOrderInitial";
import {GET_AGREEMENT_ORDER_INITIAL} from "../../../queries";
import {LoadingFragment} from "../../../pages/LoadingPage";
import {EmptyAgreements} from "../../../pages/Agreements/EmptyAgreements";
import {PulseTrackerContext} from "../Tracker";
import {
  classNames,
  FontSize,
  Heading,
  HeadingLevel, LineHeight,
  Margin,
  MaxWidth,
  Padding,
  TextAlign, Container, BackgroundColor, FontFamily, Display, JustifyContent
} from "@snoam/pinata";
import {AgreementNotActive} from "../../../pages/Order/Initialstep/OrderInitial";

const styleClass = {
  container: classNames(
    Margin.MB_8,
  ),
  pageHeading: classNames(
    FontFamily.FONT_TITLE_1,
    FontSize.TEXT_HEADLINE2,
    LineHeight.LEADING_HEADLINE2,
    Padding.PB_4,
    Padding.PT_16,
    Padding.SM_PX_0,
    Padding.PX_4,
  ),
  textContainer: classNames(
    Margin.MB_8,
    Margin.ML_AUTO,
    Margin.MR_AUTO,
    Padding.MD_PX_0,
    MaxWidth.MAX_W_MD,
    Padding.PX_2,
    TextAlign.TEXT_CENTER,
  ),
  image: classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_CENTER,
    Margin.MT_12,
  )
};


interface IActiveAgreementBoundaryProps extends MinBedriftRouteComponentProps<{agreementNumber: string}> {

}

const ActiveAgreementBoundary: React.FC<IActiveAgreementBoundaryProps> = (props) => {
  const pulseContext = useContext(PulseTrackerContext);
  const agreementNumber = +props.match.params.agreementNumber;
  const variables: GetAgreementOrderInitialVariables = { agreementNumber };
  const { data, error, loading } = useQuery<GetAgreementOrderInitial, GetAgreementOrderInitialVariables>(GET_AGREEMENT_ORDER_INITIAL, {
    variables,
    fetchPolicy: "cache-first"
  });

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error || !data || !data.agreement) {
    return(
      <>
        <Container className={styleClass.container}>
        <div className={classNames(BackgroundColor.BG_WHITE)}>
          <EmptyAgreements error={error} message={`Fant ikke noen avtale med avtalenummer ${agreementNumber}.`}/>
        </div>
        </Container>
      </>
    );
  }

  const agreement = data.agreement;

  if (!agreement.settings.enableOrdering){
    pulseContext.action.setTrackMessage(" - Inactive invitation");
  }

  if (!agreement.settings.enableOrdering) {
    return (
      <>
      <Container className={styleClass.container}>
      <div className={classNames(BackgroundColor.BG_WHITE)}>

        <div className={classNames(MaxWidth.MAX_W_XL, Margin.MX_AUTO)}>
          <Heading level={HeadingLevel.FOUR} className={styleClass.pageHeading}>
            {`${agreement.accountName} har inngått en avtale med Schibsted som gjør at du kan få abonnement på utvalgte aviser.`}
          </Heading>
        </div>

        <div className={classNames(MaxWidth.MAX_W_XS,Margin.MX_AUTO,Padding.PT_4,Padding.PB_14, Padding.SM_PB_10)}>
          <AgreementNotActive />
        </div>
      </div>
      <div className={styleClass.image}>
        <img src={'/svg/zero_subscribers.svg'} alt={'Ingen aktive avtaler'}/>
      </div>
    </Container>
    </>

    );
  }

  return (
    <>{props.children}</>
  )
};

export default ActiveAgreementBoundary;
