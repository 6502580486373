import * as React from "react";
import {RefObject} from "react";
import {
  AlignItems,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  FontWeight,
  Grid,
  GridComposition,
  GridGutter,
  GridItem,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  JustifyContent,
  LineHeight,
  Margin,
  MaxWidth,
  Padding,
  StyledRouterLink,
  Width
} from "@snoam/pinata";
import {generatePath} from "react-router-dom";
import {agreementPageRoute} from "../../routes/routes";
import SelectedProductBox from "../../components/SelectedProductBox/SelectedProductBox";
import {trackClickEngagment} from '../../utils';
import {GetAllProducts_availableProducts} from '../../__generated__/GetAllProducts';
import {GetAgreementProducts_me_agreementsWithDetails_products} from '../../__generated__/GetAgreementProducts';
import {SyntheticAgreementProduct} from '../../components/GroupProducts/GroupProducts';
import {MaybeAdminType} from "../../components/Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";


const styleClass = {
  container: classNames(
    Padding.PB_10,
    MaxWidth.MAX_W_6XL,
    Margin.M_AUTO,
  ),
};

interface IActivatedProducts {
  agreementNumber: number;
  agreementDiscountPercent: number;
  availableProductsDivRef: RefObject<HTMLDivElement>;
  products: SyntheticAgreementProduct[][];
  maxSubscriptionsPerEmployee: number;
  userRole: MaybeAdminType;
}


const ActivatedProductsHeader: React.FunctionComponent<{ products: any[] }> = ({products}) => {
  return <>{products.length > 0 ? "Produkter vi tilbyr våre ansatte" : "Det er ingen produkter tilgjengelig for ansatte"}</>
};

const ActivatedProductsDescription: React.FunctionComponent<{ products: any[]; maxSubscriptionsPerEmployee: number; agreementNumber: number; }> = ({products, maxSubscriptionsPerEmployee, agreementNumber}) => {
  const linkAgreement = generatePath(agreementPageRoute.path, {agreementNumber});

  const trackChangeProductClick = () => {
    trackClickEngagment('Produkter - Endre');
  };

  if (products.length > 0) {
    return (
      <>
        {/* eslint-disable-line eqeqeq */}Ansatte {maxSubscriptionsPerEmployee == 0 ?
        <>kan velge <StyledRouterLink to={`${linkAgreement}?tab=settings`} onClick={trackChangeProductClick}
                                      title="Endre maks antall produkter pr. ansatt.">ubegrenset</StyledRouterLink> med
          produkter hver</> :
        <>kan velge <StyledRouterLink
          to={`${linkAgreement}?tab=settings`}
          onClick={trackChangeProductClick}
          title="Endre maks antall produkter pr. ansatt.">
          maks {maxSubscriptionsPerEmployee} produkter
        </StyledRouterLink> hver</>}.
        Priser under gjelder for avtalen pr. abonnent.
      </>
    )
  }
  return (
    <>
      Legg til de produktene du ønsker at de ansatte skal kunne velge. <br/>
      Ansatte kan velge <StyledRouterLink
      to={`${linkAgreement}?tab=settings`}
      onClick={trackChangeProductClick}
    >
      maks {maxSubscriptionsPerEmployee} produkter
    </StyledRouterLink> hver.
      Priser under gjelder for avtalen pr. abonnent
    </>
  )
};

const ActivatedProducts = ({agreementNumber, products, availableProductsDivRef, maxSubscriptionsPerEmployee, agreementDiscountPercent, userRole}: IActivatedProducts) => {

  const onButtonClick = () => {
    if (availableProductsDivRef.current) {
      availableProductsDivRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  };

  return (
    <>
    <div className={styleClass.container}>
      <Grid composition={GridComposition.DELTA} className={Padding.MD_PY_8}>

        <GridItem className={classNames(Display.FLEX, FlexDirection.FLEX_COL)}>
          <Heading level={HeadingLevel.ONE} className={classNames(FontWeight.FONT_LIGHT, Margin.MB_2)}>
            <ActivatedProductsHeader products={products}/>
          </Heading>
          <p className={classNames(LineHeight.LEADING_NORMAL)}>
            <ActivatedProductsDescription
              products={products}
              agreementNumber={agreementNumber}
              maxSubscriptionsPerEmployee={maxSubscriptionsPerEmployee}
            />
          </p>
        </GridItem>

        {userRole !== AdminType.SUPEROBSERVER &&
          <GridItem
            className={classNames(Display.FLEX, JustifyContent.MD_JUSTIFY_END, JustifyContent.JUSTIFY_CENTER, AlignItems.ITEMS_END)}>
            <Button
              text={"Legg til produkter"}
              ariaLabel={"Legg til produkter"}
              skin={ButtonSkin.PRIMARY}
              className={classNames(Width.W_48, FontWeight.FONT_NORMAL, Margin.MY_0)}
              iconLeft={<Icon name={IconName.PLUS} color={IconColor.PRIMARY_LIGHT}/>}
              size={ButtonSize.SMALL}
              onClick={onButtonClick}
            />
          </GridItem>
        }
      </Grid>

      {
        products.length > 0 &&
        <Grid gutter={GridGutter.NONE}>
          {
            products
              .map((p: Array<GetAllProducts_availableProducts | GetAgreementProducts_me_agreementsWithDetails_products>, key: number) => (
                <SelectedProductBox
                  modalKey={key}
                  isActivated={true}
                  key={key}
                  products={p}
                  agreementNumber={agreementNumber}
                  agreementDiscountPercent={agreementDiscountPercent}
                />
              ))
          }
        </Grid>
      }
      </div>
    </>
  )
};

export default ActivatedProducts;
