import React, {useEffect, useState} from "react";
import {GetAgreement_me_agreementsWithDetails} from "../../__generated__/GetAgreement";
import {
  AlignItems,
  classNames,
  Display,
  FlexDirection, FontSize,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  Padding, TextColor,
  Toggle,
  ToggleSize,
  ToggleSkin
} from "@snoam/pinata";
import {styleClass} from "./SettingsPanel";
import {FetchResult, MutationFunction} from "@apollo/client";
import {UpdateAgreementSettings, UpdateAgreementSettingsVariables} from "../../__generated__/UpdateAgreementSettings";
import {useMutation} from "@apollo/client";
import {UPDATE_AGREEMENT_SETTINGS} from "../../mutations";
import {removeTypename} from "../../utils";
import InputWithValidator from "../../components/InputWithValidator/InputWithValidator";
import {RbacSuperAdminOnly} from "../../components/Boundary";
import moment from "moment";

export interface Props {
  agreement: GetAgreement_me_agreementsWithDetails;
}

const ActivationDegreeSection: React.FC<Props> = ({ agreement }) => {
  const [activationPotential, setActivationPotential] = useState<number | null>(
    agreement.settings.activationPotential
  );
  const [message, setMessage] = useState<null | JSX.Element>(null);
  const [valid, setValid] = useState<boolean>(true);
  const updateAgreementSettings: MutationFunction<UpdateAgreementSettings, UpdateAgreementSettingsVariables> = useMutation<UpdateAgreementSettings, UpdateAgreementSettingsVariables>(UPDATE_AGREEMENT_SETTINGS)[0];

  useEffect(() => {
    if (activationPotential !== agreement.settings.activationPotential) {
      updateActivationPotential(activationPotential !== undefined ? activationPotential : null);
    }
  }, [activationPotential]);

  const {
    openidConnectClientId,
    openidConnectClientSecretMasked,
    openidConnectDiscoveryUrl,
    openidConnectSystemName,
    employeePeriodicVerificationSettings,
    ...newSettings
  } = removeTypename(agreement.settings);

  const updateShowActivationScore = (): Promise<void | FetchResult<UpdateAgreementSettings>> => {
    const variables: UpdateAgreementSettingsVariables = {
      agreementNumber: agreement.agreementNumber,
      agreementId: agreement.id,
      settings: {
        ...newSettings,
        showActivationDegreeForCompanyAdmin: !agreement.settings.showActivationDegreeForCompanyAdmin,
      }
    };
    return updateAgreementSettings({variables});
  }

  const save = (activationPotential: number | null): Promise<void | FetchResult<UpdateAgreementSettings>> => {
    const variables: UpdateAgreementSettingsVariables = {
      agreementNumber: agreement.agreementNumber,
      agreementId: agreement.id,
      settings: {
        ...newSettings,
        activationPotential,
      },
    };
    return updateAgreementSettings({ variables });
  };
  const updateActivationPotential = (newActivationPotential: number | null) => {
    setMessage((
      <>
        <Icon name={IconName.LOADING_WHEEL} color={IconColor.NEUTRAL_4} className={'spin'}/> Lagrer...
      </>
    ));

    const started = moment();
    const minDelayMs = 1000;
    save(newActivationPotential)
      .then((r) => {
        const finished = moment();
        const elapsed = finished.diff(started);
        const delay = Math.max(0, Math.min(minDelayMs - elapsed, minDelayMs));
        return new Promise(((resolve) => {
          setTimeout(() => resolve(r), delay);
        }));
      })
      .then(() => {
        setMessage((
          <>
            <Icon name={IconName.CHECK} color={IconColor.POSITIVE}/> Lagret!
          </>
        ));
        setTimeout(() => {
          setMessage(null);
        }, 5000);
      })
      .catch((e) => {
        setMessage((
          <>
            <Icon name={IconName.ERROR}/> Lagring feilet!
            <pre>{JSON.stringify(e, null, 2)}</pre>
          </>
        ));
      });
  };

  const validator = (value?: string) => {
    const isValid = value ? !isNaN(parseInt(value, 10)) : false;
    setValid(isValid);
    return isValid;
  };
  return (
    <RbacSuperAdminOnly>
      <div className={styleClass.settings.section}>
        <div>
          <Heading level={HeadingLevel.TWO} className={styleClass.settings.sectionTitle}>
            Hva er potensialet på avtalen i antall abonnement?
          </Heading>
          <div>
            <InputWithValidator
              validator={validator}
              required={false}
              errorMsg={valid ? undefined : "Må være et tall"}
              onChange={(e) => validator(e.target.value) ? setActivationPotential(e.target.value ? +e.target.value : null) : () => {}}
              value={activationPotential !== null ? activationPotential : undefined}
              />
          </div>
        </div>
        <div className={classNames(Display.FLEX, FlexDirection.FLEX_ROW, AlignItems.ITEMS_CENTER)}>
          <Heading level={HeadingLevel.TWO} className={classNames(styleClass.settings.sectionTitle, Padding.PR_4)}>
            Vis aktiveringsscore til bedriftsadmin
          </Heading>
          <Toggle
            key={`show-activation-potential-${agreement.settings.showActivationDegreeForCompanyAdmin}`}
            disabled={activationPotential === null}
            size={ToggleSize.SMALL}
            skin={ToggleSkin.CONFIRM}
            checked={agreement.settings.showActivationDegreeForCompanyAdmin || false}
            asyncAction={updateShowActivationScore}
          />
        </div>
        {activationPotential === null &&
          <p className={classNames(TextColor.TEXT_NEGATIVE, FontSize.TEXT_SM)}>Avtalepotensial må være satt for å vise aktiveringsscore</p>
        }
        {message}
      </div>
    </RbacSuperAdminOnly>
  );
};

export default ActivationDegreeSection;
