import {
  BackgroundColor,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Container,
  Display,
  Height,
  Margin,
  Padding,
  Width,
  ZIndex,
  FontFamily,
  FontSize,
  TextColor,
  MaxWidth,
  LineHeight,
  Position
} from "@snoam/pinata";
import Body from "../components/Body/Body";
import * as React from "react";
import {MouseEventHandler} from "react";
import {useSpid} from "@snoam/mono-spid";
import SvgImage from "../components/SvgImage/SvgImage";
import {teaser} from '../utils';
import {Redirect} from "react-router";

const styleClass = {
  spacer: classNames(
    Display.FLEX,
    Height.H_FULL,
  ),
  front: classNames(
    ZIndex.Z_10,
    Position.RELATIVE,
    Width.W_FULL,
    Width.MD_W_64,
  ),
  outerContainer: classNames(
    BackgroundColor.BG_TERTIARY_3,
    Position.RELATIVE,
    Margin.LG_ML_56,
    Margin.MD_MX_AUTO,
  ),
  innerContainer: classNames(
    Padding.PT_10,
    Padding.MD_PT_40,
    MaxWidth.LG_MAX_W_2XL,
  ),
  heading: classNames(
    FontFamily.FONT_TITLE_1,
    FontSize.MD_TEXT_5XL,
    FontSize.TEXT_HEADLINE1,
    LineHeight.MD_LEADING_TIGHT,
    TextColor.TEXT_WHITE,
  ),
  secondLine: classNames(
    FontFamily.FONT_TITLE_2,
    FontSize.MD_TEXT_HEADLINE1,
    FontSize.TEXT_HEADLINE2,
    MaxWidth.LG_MAX_W_XL,
    TextColor.TEXT_WHITE,
    Padding.PY_2,
    Padding.MD_PR_20,
  )
};

export const LoginContextReady = ({login}: { login: MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <React.Fragment>
      <Button
        className={styleClass.front}
        skin={ButtonSkin.SECONDARY}
        size={ButtonSize.MEDIUM}
        text={'Logg inn'}
        ariaLabel={'Logg inn'}
        onClick={login}
      />
    </React.Fragment>
  )
};

export const NotLoggedIn = () => {

  const spidContext = useSpid();
  const {state: {userId}} = spidContext;

  const login = () => {
    sessionStorage.removeItem("orderFlowUrl");
    spidContext.actions.login({simplified: false, teaser: teaser()});
  };

  if (userId) {
    const orderFlowUrl = sessionStorage.getItem("orderFlowUrl");
    sessionStorage.removeItem("orderFlowUrl");
    if(orderFlowUrl) {
      return <Redirect exact={true} to={`${orderFlowUrl}`}/>
    }
    return <Redirect exact={true} to={'/admin/dashboard'} from={'/'}/>
  }
  return (
    <>
      <div className={styleClass.spacer}>

      <Container className={styleClass.outerContainer}>
        <div className={styleClass.innerContainer}>
          <Body>
              <p className={styleClass.heading}>Velkommen til Schibsteds bedriftsadmin </p>
               <>
              <p className={styleClass.secondLine}>Her får du oversikt over din bedriftsavtale hos Schibsted.</p>
              <LoginContextReady login={login}/>
            </>
          </Body>

        </div>
        <SvgImage
          href={`${process.env.PUBLIC_URL}/svg/pattern_powder_blue.svg`}
          className={classNames('circle_logo',Width.LG_W_FULL,Width.W_XL)}
        />
      </Container>

      </div>
    </>
  )
};
