import * as React from 'react';
import {
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FontWeight,
  Grid,
  GridComposition,
  Heading,
  HeadingLevel,
  Margin,
  Padding,
  MaxWidth,
} from "@snoam/pinata";
import EditProduct from "../../components/EditProduct/EditProduct";
import {useQuery} from "@apollo/client";
import {
  GetAllProducts,
  GetAllProducts_availableProducts,
  GetAllProductsVariables
} from "../../__generated__/GetAllProducts";
import {GET_ALL_PRODUCTS} from "../../queries";
import {LoadingFragment} from "../LoadingPage";
import {EmptyAgreements} from "../Agreements/EmptyAgreements";
import {useEffect, useState} from "react";
import GroupProducts, {getGroupOfProducts, getSingleGroupProduct} from "../../components/GroupProducts/GroupProducts";
import {MinBedriftRouteComponentProps} from "../../routes/utils";

interface IProducts {
  active: GetAllProducts_availableProducts[]
  deactivated: GetAllProducts_availableProducts[]
}

const EditProducts: React.FunctionComponent<MinBedriftRouteComponentProps> = () => {
  const {data, error, loading} = useQuery<GetAllProducts, GetAllProductsVariables>(GET_ALL_PRODUCTS, {
    variables: {
      showOtherProducts: true,
    },
    fetchPolicy: "cache-first"
  });

  const [products, setProducts] = useState<IProducts>({active: [], deactivated: []});

  useEffect(() => {
    if(data && data.availableProducts && data.availableProducts.length > 0) {
      const groupProducts = GroupProducts(data.availableProducts);
      const active = groupProducts.grouped.filter((product) => getSingleGroupProduct(product).productVariationActive) as GetAllProducts_availableProducts[];
      const deactivated = groupProducts.grouped.filter((product) => !getSingleGroupProduct(product).productVariationActive) as GetAllProducts_availableProducts[];
      setProducts({
        active,
        deactivated
      });
    }
  },[data]);

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error || !data || data.availableProducts.length <= 0) {
    return <EmptyAgreements error={error} message={'Her er det foreløbig ingen produkter å se.'}/>;
  }

  return (
    <Container width={ContainerWidth.WIDER} className={classNames(Margin.MT_8, Padding.PX_4, Display.FLEX, FlexDirection.FLEX_COL)}>
      <div className={classNames(MaxWidth.MAX_W_6XL,Margin.MX_AUTO)}>
          <Heading level={HeadingLevel.ONE} className={classNames(FontWeight.FONT_LIGHT,Padding.PL_3, Margin.MB_2, Margin.ML_2)}>
            Aktive produkter
          </Heading>
          <Grid composition={GridComposition.ALPHA}>
            {products.active.map((product, key) => {
              return (
                <div className={classNames(Padding.PX_3,Padding.PY_3)}>
                  <EditProduct product={getGroupOfProducts(product) as GetAllProducts_availableProducts[]} key={key} id={`1${key}`} />
                </div>
              )
            })}
          </Grid>
          <Heading level={HeadingLevel.ONE} className={classNames(FontWeight.FONT_LIGHT, Padding.PL_4, Margin.MB_2, Margin.ML_2)}>
            Deaktiverte produkter
          </Heading>
          <Grid composition={GridComposition.ALPHA}>
            {products.deactivated.map((product, key) => {
              return (
                <div className={classNames(Padding.PX_3,Padding.PY_3)}>
                  <EditProduct product={getGroupOfProducts(product) as GetAllProducts_availableProducts[]} key={key} id={`2${key}`} />
                </div>
              )
            })}
          </Grid>
      </div>
    </Container>
  )
};

export default EditProducts;
