import * as React from 'react';
import {
  BackgroundColor,
  classNames,
  Cursor,
  Display,
  Icon,
  IconName,
  IconSize,
  Margin,
  Padding,
  Position,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  StyledRouterLink,
  PointerEvents,
  TextDecoration, BorderRadius, BorderColor, BorderWidth, Height, MinWidth,
} from "@snoam/pinata";

import {RouteComponentProps, withRouter} from "react-router";
import {FlexDirection} from "@snoam/pinata";
import {JustifyContent} from "@snoam/pinata";
import {AlignItems} from "@snoam/pinata";

interface IOrderButtonProps {
  text: string;
  url?: string;
  absoluteUrl?: boolean;
  backBtnUrl?: string;
  canProceed: boolean;
  onProceedClick?: (e: any) => void;
  canProceedBackgroundColor?: BackgroundColor;
  center?: boolean
}

const styleClass = {
  root: (canProceed: boolean) => classNames(
    Display.FLEX,
    FlexDirection.XS_FLEX_COL_REVERSE,
    FlexDirection.SM_FLEX_ROW,
    Position.FIXED,
    Position.BOTTOM_0,
    Position.LEFT_0,
    Position.RIGHT_0,
    Padding.P_4,
    BackgroundColor.BG_NEUTRAL_2,
    BorderColor.BORDER_NEUTRAL_3,
    BorderWidth.BORDER_T_1,
    Width.W_FULL,
    canProceed ? Cursor.CURSOR_POINTER : null,
  ),
  button: (canProceed: boolean, canProceedBackgroundColor?: BackgroundColor, center?: boolean) => classNames(
    canProceed ? (canProceedBackgroundColor || BackgroundColor.BG_PRIMARY_1) : BackgroundColor.BG_NEUTRAL_4,
    !canProceed ? PointerEvents.POINTER_EVENTS_NONE : null,
    Padding.MD_P_4,
    Padding.P_2,
    MinWidth.XS_MIN_W_FULL,
    center ? Margin.MX_AUTO : Margin.MR_AUTO,
    !center ? Margin.SM_ML_2: null,
    !center ? Margin.XS_M_AUTO: null,
    Margin.XS_MY_1,
    Margin.SM_MY_0,
    TextColor.TEXT_WHITE,
    BorderRadius.ROUNDED_BRAND,
    Height.SM_H_12,
    Height.XS_H_10,
    MinWidth.SM_MIN_W_64,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    TextAlign.TEXT_CENTER,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
  ),
  leftButton: classNames(
    BackgroundColor.BG_WHITE,
    Display.FLEX,
    BorderRadius.ROUNDED_BRAND,
    Height.SM_H_12,
    Height.XS_H_10,
    MinWidth.SM_MIN_W_64,
    FlexDirection.FLEX_ROW,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
    Padding.PX_4,
    Margin.SM_MR_2,
    Margin.XS_M_AUTO,
    Margin.XS_MT_1,
    MinWidth.XS_MIN_W_FULL,
    Margin.SM_MT_0,
    Margin.ML_AUTO,
    TextDecoration.NO_UNDERLINE,
    TextColor.TEXT_BLACK,
    TextAlign.TEXT_CENTER,
    FontSize.MD_TEXT_LG,
    FontSize.TEXT_BASE
  ),
  rightButton: () => classNames(
    Margin.M_AUTO,
    TextDecoration.NO_UNDERLINE,
    FontSize.MD_TEXT_LG,
    FontSize.TEXT_BASE
  )
};

const ButtonSkin: React.FunctionComponent<{canProceed: boolean, text: string, canProceedBackgroundColor?: BackgroundColor, center?: boolean}> = (props) => {
  return (
    <div
      className={styleClass.button(props.canProceed, props.canProceedBackgroundColor, props.center)}>
      <div className={Width.W_FULL}>{props.text}</div>
      <Icon
        name={IconName.CHEVRON_RIGHT}
        size={IconSize.MEDIUM}
      />
    </div>
  )
};

export const ButtonLink: React.FunctionComponent<IOrderButtonProps> = (props) => {
  const {text, url, absoluteUrl, canProceed, canProceedBackgroundColor, onProceedClick} = props;
  const onProceedKeyDown = (__event: any) => {
    if (onProceedClick && (__event.key === "Enter" || __event.key === " ")) {
      __event.preventDefault();
      onProceedClick(__event);
    }
  };

  const link = url && (absoluteUrl ? (
    <a href={canProceed ? url : '#'} className={styleClass.rightButton()}>
      <ButtonSkin
        canProceed={canProceed}
        center={props.center}
        canProceedBackgroundColor={canProceedBackgroundColor}
        text={text}
      />
    </a>
  ) : (
    <StyledRouterLink to={canProceed ? url : '#'} className={styleClass.rightButton()}>
      <ButtonSkin
        canProceed={canProceed}
        center={props.center}
        canProceedBackgroundColor={canProceedBackgroundColor}
        text={text}
      />
    </StyledRouterLink>
  ));

  return (
    <>
      {link}
      {!url &&
        <div onClick={onProceedClick} tabIndex={0} onKeyDown={onProceedKeyDown} role="button" className={classNames(Display.FLEX, FlexDirection.FLEX_ROW, !props.center ? Margin.SM_MR_AUTO : Margin.SM_MX_AUTO)}>
          <ButtonSkin
            canProceed={canProceed}
            center={props.center}
            text={text}
          />
        </div>
      }
    </>
  )
};

const LinkButton = withRouter((props: IOrderButtonProps & RouteComponentProps) => {
  return (
    <div className={styleClass.root(props.canProceed)}>
      {props.backBtnUrl &&
        <StyledRouterLink to={props.backBtnUrl} className={styleClass.leftButton}>
          <Icon
            name={IconName.CHEVRON_LEFT}
            size={IconSize.MEDIUM}
            className={Margin.MR_AUTO}
          />
          <div className={Margin.MR_AUTO}>Tilbake</div>
        </StyledRouterLink>
      }
      {props.canProceed ?
        <ButtonLink  {...props} center={!props.backBtnUrl}/>
      : <ButtonSkin
          canProceed={props.canProceed}
          center={!props.backBtnUrl}
          canProceedBackgroundColor={props.canProceedBackgroundColor}
          text={props.text}
          aria-label={props.text}
        />}
    </div>
  )
});

const OrderButton = (props: IOrderButtonProps) => (
  <>
    <LinkButton {...props}/>
  </>
);

export default OrderButton;
