import {
  Button,
  ButtonSkin,
  classNames,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Margin,
  MaxWidth,
  Width
} from '@snoam/pinata';
import * as React from 'react';
import { checkNoErrors, DELETE_ADMIN } from '../../mutations';
import {MutationFunction, PureQueryOptions, useMutation} from "@apollo/client";
import {DeleteAdmin, DeleteAdminVariables} from "../../__generated__/DeleteAdmin";
import {GET_AGREEMENT} from "../../queries";
import {GetAgreement_me_agreementsWithDetails_admins, GetAgreementVariables} from "../../__generated__/GetAgreement";
import {useState} from "react";
import {useContext} from "react";
import {DepartmentContext} from "../../context/DepartmentContext/DepartmentContext";

const debug = require('debug')('minbedrift:client:administrator:removeButton');

const styleClass = {
  button: classNames(
    Margin.MT_0,
    MaxWidth.MAX_W_XS,
    Width.W_FULL,
    Margin.MT_4
  ),
};

interface IAdministratorRemoveButton {
  spid: number;
  agreementAdmins: GetAgreement_me_agreementsWithDetails_admins[];
  triggerTooltip: () => void;
  agreementNumber: number;
}

const AdministratorRemoveButton: React.FunctionComponent<IAdministratorRemoveButton> = ({spid, agreementAdmins, agreementNumber, triggerTooltip}) => {
  const deleteAdminMutation: MutationFunction<DeleteAdmin, DeleteAdminVariables> = useMutation<DeleteAdmin, DeleteAdminVariables>(DELETE_ADMIN)[0];
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const activeDepartment = useContext(DepartmentContext);
  const refetchQueries: PureQueryOptions[] = [{
    query: GET_AGREEMENT,
    variables: ((): GetAgreementVariables => {
      return {
        agreementNumber: [agreementNumber],
        shouldFetchSubscriptions: true,
        department: activeDepartment ? activeDepartment : null
      };
    })(),
  }];

  const admin = agreementAdmins.find(admin => admin.spid === spid);
  const adminId = admin && admin.id;

  const onDeleteAdminClick = async () => {
    if (adminId) {

      setIsSaving(true);
      deleteAdminMutation({
        refetchQueries,
        variables: {
          id: adminId,
        }
      }).then((r) => {

        setIsSaving(false);
        setIsSaved(true);
        triggerTooltip();
        return r ? checkNoErrors(r) : Promise.reject(`Expected return value, got: ${r}`)
      }).catch((err) => {

        setIsSaving(false);
        debug("err: ", err)
      });
    }
  };

  return (
    <Button
      text={isSaved ? "Fjernet" : "Fjern som administrator"}
      ariaLabel={isSaved ? "Fjernet" : "Fjern som administrator"}
      skin={ButtonSkin.SECONDARY}
      iconRight={isSaved ? <Icon name={IconName.CHECK} color={IconColor.POSITIVE} size={IconSize.LARGE} /> : undefined}
      className={styleClass.button}
      onClick={onDeleteAdminClick}
      loading={isSaving}
      loadingText="Fjerner..."
      style={{transition: 'background-color 500ms ease'}}
      iconLeft={<Icon name={IconName.CLOSE}/>}
    />
  );
};

export default AdministratorRemoveButton;
