import * as React from "react";
import {
  Button,
  classNames,
  Display,
  FontFamily,
  FontWeight,
  Grid,
  Heading,
  HeadingLevel,
  Height,
  Margin,
  MaxWidth,
  Padding,
  Fill,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  ButtonSkin,
  FlexDirection,
  AlignItems,
  FlexShrink
} from "@snoam/pinata";
import SvgImage from "../../components/SvgImage/SvgImage";
import {generatePath, RouteComponentProps, withRouter} from "react-router-dom";
import {inviteEmployeesPageRoute, productsPageRoute} from "../../routes/routes";

const styleClass = {
  noSubscribers: {
    root: classNames(
      TextAlign.TEXT_CENTER,
      Padding.PB_10,
      FlexDirection.FLEX_COL,
      Display.FLEX,
      AlignItems.ITEMS_CENTER
    ),
    heading: classNames(
      FontWeight.FONT_LIGHT,
      FontSize.TEXT_2XL
    ),
    paragraph: classNames(
      Padding.PY_4,
      TextColor.HOVER_TEXT_NEUTRAL_7,
      FontSize.TEXT_LG,
      FontFamily.FONT_TITLE_2
    ),
    addBtn: {
      iconLeft: classNames(
        Margin.ML_2,
        Margin.MR_4,
        Margin.MY_2,
        Fill.FILL_WHITE,
        FlexShrink.FLEX_SHRINK_0,
        Height.H_6,
        Width.W_6,
      ),
      iconRight: classNames(
        Fill.FILL_WHITE,
        FlexShrink.FLEX_SHRINK_0,
        Margin.M_2,
        Height.H_6,
        Width.W_6,
      )
    },
    iconProduct: classNames(
      Fill.FILL_PRIMARY_1,
      FlexShrink.FLEX_SHRINK_0,
      Margin.M_2,
      Height.H_6,
      Width.W_6,
    )
  },
  container: '',
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_2XL,
    Width.W_3_4,
    Padding.PL_4,
    Padding.MD_PL_0,
  ),
  tableControls: classNames(
    Margin.MB_2,
    AlignItems.ITEMS_CENTER,
    FlexShrink.FLEX_SHRINK,
    Padding.PL_0,
  ),
};

interface IEmptySubscriptionProps extends RouteComponentProps {
  agreementNumber: number;
}

const EmptySubscriptions = ({history, agreementNumber}: IEmptySubscriptionProps) => (
  <Grid className={classNames(TextAlign.TEXT_CENTER)}>

    <SvgImage href={`${process.env.PUBLIC_URL}/svg/zero_subscribers.svg`} title={'Ingen aktive abonnenter'} className={Width.W_FULL}/>

    <Heading level={HeadingLevel.TWO} className={styleClass.noSubscribers.heading}>
      Velkommen!
    </Heading>

    <Heading level={HeadingLevel.TWO} className={styleClass.noSubscribers.heading}>
      Dere har foreløpig ingen abonnenter.
    </Heading>

    <p className={styleClass.noSubscribers.paragraph}>
      Kom i gang!
    </p>

    <Button
      text={"Gi ansatte abonnement"}
      ariaLabel={"Gi ansatte abonnement"}
      onClick={() => history.push(generatePath(inviteEmployeesPageRoute.path, {agreementNumber}))}
      skin={ButtonSkin.PRIMARY}
      iconLeft={
        <SvgImage
          href={`${process.env.PUBLIC_URL}/svg/employees.svg`}
          className={styleClass.noSubscribers.addBtn.iconLeft}
        />
      }
      iconRight={
        <SvgImage
          href={`${process.env.PUBLIC_URL}/svg/add.svg`}
          className={styleClass.noSubscribers.addBtn.iconRight}
        />
      }
      className={classNames(MaxWidth.MAX_W_XS, Width.W_FULL, Margin.MX_AUTO)}
    />

    <Button
      text={"Se produkter i avtalen"}
      ariaLabel={"Se produkter i avtalen"}
      onClick={() => history.push(generatePath(productsPageRoute.path, {agreementNumber}))}
      skin={ButtonSkin.SECONDARY}
      className={classNames(Margin.MT_0, MaxWidth.MAX_W_XS, Width.W_FULL, Margin.MX_AUTO)}
      iconLeft={
        <SvgImage
          href={`${process.env.PUBLIC_URL}/svg/products.svg`}
          className={styleClass.noSubscribers.iconProduct}
        />
      }
    />

  </Grid>
);

export default withRouter(EmptySubscriptions)
