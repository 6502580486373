import * as React from "react";
import {useContext} from "react";
import {
  Accordion,
  AccordionPanel,
  BackgroundColor,
  BorderWidth,
  Card,
  classNames,
  Container,
  ContainerWidth,
  FontWeight,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  Margin,
  Overflow,
  Padding,
} from "@snoam/pinata";
import SettingsPanel from './SettingsPanel';
import AgreementPanel from './AgreementPanel';
import AdminsPanel from './AdminsPanel';
import AdminIcon from './AdminIcon';
import {useQuery} from '@apollo/client';
import {LoadingFragment} from '../LoadingPage';
import {GetAgreement, GetAgreementVariables,} from '../../__generated__/GetAgreement';
import {GET_AGREEMENT} from '../../queries';
import qs from 'qs';
import AccordionPanelTitle from './AccordionPanelTitle';
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {Helmet} from "react-helmet";
import {MinBedriftRouteComponentProps} from "../../routes/utils";
import ErrorPage from "../ErrorPage/ErrorPage";
import {useRole} from "../../components/Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";

const styleClass = {
  heading: classNames(
    FontWeight.FONT_LIGHT,
    Margin.MB_8,
    Margin.ML_4,
    Margin.MD_ML_0,
  ),
  accordionTitle: classNames(
    BackgroundColor.BG_WHITE,
    Margin.MT_1,
    BorderWidth.BORDER_0,
  ),
  accordionTitleIcon: classNames(
    Margin.MR_4,
  ),
  accordionContent: classNames(
    BackgroundColor.BG_NEUTRAL_1,
    Padding.P_0,
    Overflow.OVERFLOW_VISIBLE,
  ),
};

enum AdminPanels {
  AGREEMENT = 'agreement',
  ADMINS = 'admins',
  SETTINGS = 'settings',
}

interface AgreementProps extends MinBedriftRouteComponentProps<{ agreementNumber: string }> {}

const AgreementSettings: React.FunctionComponent<AgreementProps> = ({match, history, location, route}) => {

  const {mobile} = useContext(ResponsiveContext);
  const agreementNumber = [+match.params.agreementNumber];
  const variables = {agreementNumber};
  const {data, error, loading} = useQuery<GetAgreement, GetAgreementVariables>(GET_AGREEMENT, {
    variables,
    fetchPolicy: "cache-first"
  });
  const {role: userRole} = useRole();

  const search = location.search.slice(1);
  const tabParam = qs.parse(search).tab;

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error) {
    throw error;
  }

  if(userRole === AdminType.SUPEROBSERVER) {
    const message = (
      <Card className={classNames(BackgroundColor.BG_WHITE, Padding.PX_6, Padding.PY_8)}>
        Du har ikke tilstrekkelig tilgang til å kunne vise denne siden
      </Card>
    );
    return (
      <ErrorPage
        heading={'Beklager!'}
        message={message}/>
    );
  }

  const [agreement] = data!.me.agreementsWithDetails;
  const {admins} = agreement;
  const onAccordionPanelTitleClick = (panel: AdminPanels) => () => {
    if (panel === tabParam) {
      history.push(`?tab=none`)
    } else {
      history.push(`?tab=${panel}`)
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Min Bedrift ${agreement ? `| ${agreement.accountName} ` : ''} ${route ? `| ${route.label}` : ''}`}</title>
      </Helmet>

      <Container width={ContainerWidth.WIDE}>
        <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
          Vår avtale
        </Heading>

        <Accordion
          iconOpen={<Icon name={IconName.PLUS}/>}
          iconClose={<Icon name={IconName.MINUS}/>}
          titleClasses={styleClass.accordionTitle}
          contentClasses={styleClass.accordionContent}
        >
          <AccordionPanel
            title={
              <AccordionPanelTitle title={`Avtalenummer: ${agreementNumber}`}>
                <Icon
                  name={IconName.INFO_OUTLINE}
                  color={IconColor.NEUTRAL_4}
                  className={styleClass.accordionTitleIcon}
                />
              </AccordionPanelTitle>
            }
            onClick={onAccordionPanelTitleClick(AdminPanels.AGREEMENT)}
            expand={tabParam === AdminPanels.AGREEMENT || tabParam === undefined}
          >
            <AgreementPanel
              agreement={agreement}
              mobile={mobile}
            />
          </AccordionPanel>
          <AccordionPanel
            title={
              <AccordionPanelTitle title={'Administratorer'}>
                {AdminIcon(BackgroundColor.BG_NEUTRAL_4)}
              </AccordionPanelTitle>
            }
            onClick={onAccordionPanelTitleClick(AdminPanels.ADMINS)}
            expand={tabParam === AdminPanels.ADMINS}
          >
            <AdminsPanel
              mobile={mobile}
              admins={admins}
              agreementNumber={Number(agreementNumber)}
            />
          </AccordionPanel>
          <AccordionPanel
            title={
              <AccordionPanelTitle title={'Innstillinger'}>
                <Icon
                  name={IconName.INFO_OUTLINE}
                  color={IconColor.NEUTRAL_4}
                  className={styleClass.accordionTitleIcon}
                />
              </AccordionPanelTitle>
            }
            onClick={onAccordionPanelTitleClick(AdminPanels.SETTINGS)}
            expand={tabParam === AdminPanels.SETTINGS}
          >
            <SettingsPanel agreement={agreement}/>
          </AccordionPanel>
        </Accordion>
      </Container>
    </>
  )
};

export default AgreementSettings;
