import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  Grid,
  GridComposition,
  GridGutter,
  GridItem,
  Icon,
  IconName,
  JustifyContent,
  LineHeight,
  Margin,
  Padding,
  StyledRouterLink,
  TextTransform,
  ToggleSkin,
  Whitespace,
  Width,
} from '@snoam/pinata';
import * as React from 'react';
import {useContext} from 'react';
import {GetAgreements_me_agreements_edges} from '../../__generated__/GetAgreements';
import ToggleAgreementActivated from '../ToggleAgreementActivated/ToggleAgreementActivated';
import {generatePath, RouteComponentProps, withRouter} from "react-router-dom";
import {orderInitialPageRoute, subscribersPageRoute} from "../../routes/routes";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {useRole} from "../Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";

const styleClass = {
  agreement: {
    expandedHeader: {
      grid: classNames(
        FlexDirection.FLEX_COL,
        FlexDirection.MD_FLEX_ROW,
        BackgroundColor.BG_NEUTRAL_1,
        AlignItems.ITEMS_CENTER,
      ),
      gridItemOne: classNames(
        Display.FLEX,
        JustifyContent.JUSTIFY_BETWEEN,
        JustifyContent.MD_JUSTIFY_START,
        AlignItems.MD_ITEMS_CENTER,
        Padding.P_4,
        BorderColor.BORDER_NEUTRAL_3
      ),
      gridItemTwo: classNames(
        JustifyContent.JUSTIFY_CENTER,
        Width.W_FULL,
        Display.FLEX,
        Padding.PX_4,
        Margin.MX_AUTO
      ),
      gridItemThree: classNames(
        Display.FLEX,
        FlexDirection.FLEX_ROW,
        Padding.MD_PR_4,
        Padding.PX_4,
        JustifyContent.MD_JUSTIFY_END,
        JustifyContent.JUSTIFY_BETWEEN,
        FlexDirection.MD_FLEX_ROW,
        LineHeight.LEADING_HEADLINE3)
    },
  },
};

interface IAgreementHeaderProps extends RouteComponentProps {
  agreement: GetAgreements_me_agreements_edges;
}

const AgreementHeader = ({agreement, history}: IAgreementHeaderProps) => {
  const invitationLink = generatePath(orderInitialPageRoute.path, {agreementNumber: agreement.agreementNumber});
  const {mobile, tablet} = useContext(ResponsiveContext);
  const {role: userRole} = useRole();

  const onClick = () => {
    history.push(generatePath(subscribersPageRoute.path, {agreementNumber: agreement.agreementNumber}));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <Grid
      composition={mobile ? GridComposition.DEFAULT : GridComposition.ALPHA}
      gutter={[GridGutter.TOP_NONE, GridGutter.LEFT_NONE, GridGutter.RIGHT_NONE]}
      className={styleClass.agreement.expandedHeader.grid}
    >
      <GridItem>
        <div className={styleClass.agreement.expandedHeader.gridItemOne}>
          <StyledRouterLink
            to={invitationLink}
            className={classNames(Margin.MR_4, 'invitationLink')}
          >
            Invitasjon til ansatte
          </StyledRouterLink>
          <ToggleAgreementActivated
            agreement={agreement}
            skin={ToggleSkin.CONFIRM}
            disabled={userRole === AdminType.SUPEROBSERVER}
            ariaLabel={"Skru av og på invitasjon til ansatte"}
          />
        </div>
      </GridItem>
      <GridItem>
        <div className={styleClass.agreement.expandedHeader.gridItemTwo}>
          <Button
            text={"Gå til avtale"}
            ariaLabel={"Gå til avtale"}
            iconRight={<Icon name={IconName.CHEVRON_RIGHT}/>}
            size={ButtonSize.SMALL}
            className={Width.W_FULL}
            skin={ButtonSkin.SECONDARY}
            onKeyDown={handleKeyDown}
            onClick={onClick}
          />
        </div>
      </GridItem>
      <GridItem>
        <div className={styleClass.agreement.expandedHeader.gridItemThree}>
          <span className={classNames(Whitespace.WHITESPACE_NO_WRAP, Margin.MR_3, Margin.MD_MR_6)}>
            Avtalenr: <b>{agreement.agreementNumber}</b>
          </span>
          <span className={classNames(Whitespace.WHITESPACE_NO_WRAP)}>
            {!mobile && !tablet && <span>Avtaletype:</span>} <b
            className={TextTransform.CAPITALIZE}>{agreement.invoiceAccountType}</b>
          </span>
        </div>
      </GridItem>
    </Grid>
  );
};

export default withRouter(AgreementHeader);
