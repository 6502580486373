import React, { useContext } from "react";
import { ResponsiveContext } from "../../context/ResponsiveContext/ResponsiveContext";
import {
  classNames,
  Padding,
  BackgroundColor,
  Margin,
  Position,
  Icon,
  IconName,
  Width,
  Display,
  AlignItems,
  BoxShadow,
  FontWeight,
  FlexWrap,
  MinWidth,
  MinHeight,
  JustifyContent,
  BorderColor,
  BorderStyle,
  BorderWidth,
  TextColor
} from "@snoam/pinata";
import { useSelectionContext } from "../../context/SelectionContext/SelectionContext";
import SelectionModal from "../SelectionModal/SelectionModal";
import { ModalContext } from "@snoam/mono-modal";
import * as H from 'history';

interface ISelectionProps {
  history: Pick<H.History, 'push'>,
  agreementNumber: number,
}

const styleClass = {
  container: (desktop: boolean) =>
    classNames(
      BoxShadow.SHADOW_BORDER_NEUTRAL_4,
      Position.BOTTOM_0,
      Position.LEFT_0,
      Position.FIXED,
      Width.W_FULL,
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      FlexWrap.FLEX_WRAP,
      BackgroundColor.BG_WHITE,
      desktop ? JustifyContent.JUSTIFY_START : JustifyContent.JUSTIFY_CENTER,
      desktop ? Padding.PX_40 : Padding.PX_2,
      desktop ? Padding.LG_PY_6 : Padding.SM_PY_2,
    ),
  buttonStyle: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    FlexWrap.FLEX_WRAP
  ),
  mobileSection: classNames(
    MinWidth.MIN_W_1,
    MinHeight.MIN_H_10,
    Margin.MX_5,
    BorderWidth.BORDER_R_1,
    BorderColor.BORDER_NEUTRAL_3,
    BorderStyle.BORDER_SOLID
  ),
  mobileText: classNames(
    Margin.MR_6, 
    FontWeight.FONT_BOLD, 
    TextColor.HOVER_TEXT_PRIMARY_1
  ),
  desktopText: classNames(
    Margin.LG_MR_12, 
    Margin.MR_4, 
    Margin.LG_MY_12, 
    Margin.MY_6
  ),
};

const SelectionFooter: React.FC<ISelectionProps> = ({ agreementNumber, history }) => {
  const { desktop, mobile } = useContext(ResponsiveContext);
  const selectionContext = useSelectionContext()


  const { openModal } = useContext(ModalContext);

  const openSelectionModal = () => {
    openModal("SELECTION_MODAL")
    /* tslint:disable */
    /* eslint-disable */
  };

  const deselectAll = () => {
    selectionContext.context.actions.resetSelection();
    /* tslint:disable */
    /* eslint-disable */
  };

  const selected = selectionContext.context.actions.getSelected()
  if (selected.length > 0) {
    return (
      <div className={styleClass.container(desktop)}>
        <div onClick={deselectAll} role="button" className={styleClass.buttonStyle}>
          <Icon name={IconName.INDETERMINATE_CHECK_BOX} className={classNames(Margin.MR_4, TextColor.TEXT_WHITE, BackgroundColor.BG_NEUTRAL_4)} />
          <div className={styleClass.desktopText}>
            {`${selected.length} person${selected.length === 1 ? '' : 'er'} valgt`}
          </div>
        </div>
        {mobile && <div className={styleClass.mobileSection} />}
        <div onClick={openSelectionModal} role="button" className={styleClass.buttonStyle}>
          <Icon name={IconName.STREAMLINE_DELIVERY_STOP} className={classNames(Margin.MR_4)} />
          <span className={styleClass.mobileText}>Stopp tilgang {!mobile && 'til produkter'}</span>
        </div>
        <SelectionModal history={history} agreementNumber={agreementNumber} />
      </div>
    );
  }
  return null
};

export default SelectionFooter;
