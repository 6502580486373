import * as React from 'react';
import {
  AlignItems,
  BackgroundColor,
  Card as PinataCard,
  classNames,
  Display,
  FlexDirection,
  FlexShrink,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  JustifyContent,
  LineHeight,
  Link,
  Margin,
  Padding,
  TextAlign,
  TextDecoration,
  Width
} from "@snoam/pinata";
import SvgImage from "../../components/SvgImage/SvgImage";
import {SettingsIcon} from "../../components/Icons/Icons";
import {OrderSessionModel} from "./models/OrderSessionModel";
import {Publication, publications} from '../../utils';
import qs from 'qs';
import {MinBedriftRouteComponentProps} from "../../routes/utils";
import {useClientContext} from '../../context/ClientContextProvider';
import {clientName} from "@snoam/mono-scc";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER,
    Width.MD_W_1_2,
    Margin.MX_AUTO,
    Padding.PB_10,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_3XL,
    Margin.MB_4
  ),
  productCard: classNames(
    Width.W_FULL,
    Padding.MD_PX_8,
    Padding.PX_4,
    Padding.PY_4,
    Display.FLEX,
    JustifyContent.JUSTIFY_BETWEEN,
    Margin.MB_1,
    AlignItems.ITEMS_CENTER,
  ),
  paragraph: classNames(
    TextAlign.TEXT_CENTER,
    Padding.PB_8,
    Padding.PX_2,
    Padding.MD_PX_0,
    LineHeight.LEADING_NORMAL,
    Width.W_FULL
  )
};

interface IProducts {
  productGroup?: string | null;
}
const Products = ({ productGroup }: IProducts): JSX.Element | null => {
  const { isProduction } = useClientContext();
  const publication: Publication | undefined = publications(isProduction).find((publication) => publication.productGroup === productGroup);

  return publication ? <PinataCard
    backgroundColor={BackgroundColor.BG_WHITE}
    className={styleClass.productCard}>
    <div className={classNames(Width.W_3_5, Display.FLEX, JustifyContent.JUSTIFY_START)}>
      <Link href={publication.link} aria-label={`Les ${clientName(publication.clientShortHandName)}`}>
        <SvgImage
          href={`${process.env.PUBLIC_URL}${publication.logo}`}
          className={classNames(Display.FLEX, FlexShrink.FLEX_SHRINK_0, Width.W_FULL)}
        />
      </Link>
    </div>
    <div className={classNames(Display.FLEX, FlexDirection.FLEX_ROW, AlignItems.ITEMS_CENTER)}>
      <SettingsIcon />
      <Link
        href={publication.myPage}
        className={TextDecoration.NO_UNDERLINE}
      >
        Min side
      </Link>
    </div>
  </PinataCard> : null;
};

interface QueryParams {
  givenName: string;
  productGroups: string;
}
const ReceiptPage: React.FunctionComponent<MinBedriftRouteComponentProps> = (props) => {
  const {givenName, productGroups}: QueryParams = (qs.parse(props.history.location.search.replace(/^\?/, ''))) as unknown as QueryParams;

  const orderModel = new OrderSessionModel();
  orderModel.clear();

  return (
    <div className={styleClass.root}>
      <SvgImage
        href={`${process.env.PUBLIC_URL}/svg/celebrate.svg`}
        className={classNames(Width.W_5_6, Display.FLEX, Margin.MX_AUTO)}
      />
      <Heading
        level={HeadingLevel.TWO}
        className={styleClass.heading}
      >
        Velkommen {givenName}
      </Heading>

      <p className={styleClass.paragraph}>
        Ditt abonnement er registrert. Om du har behov for å gjøre endringer senere (som f.eks adresseendring),
        går du inn på min side for det produktet du har valgt eller ta kontakt med administrator som sendte deg
        invitasjonen. God lesning!
      </p>
      {
        Object
          .keys(productGroups)
          .reduce((acc, groupName) => {
            acc.push(productGroups[groupName]);
            return acc;
          }, [] as string[]).map((product) => {
          return (
            <Products
              key={product}
              productGroup={product}
            />
          )
        })
      }
    </div>
  );
};

export default ReceiptPage;
