import * as React from 'react';
import { MouseEventHandler, useEffect, useState } from 'react';
import {
  AlignItems,
  Arrow,
  Card,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FontSize,
  FontWeight,
  Grid,
  GridGutter,
  GridItem,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  JustifyContent,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  TransparentSkin
} from '@snoam/pinata';
import ReferencesPerProductBox from '../../components/ReferencesPrProductBox/ReferencesPrProductBox';
import EmailVerificationBox from '../../components/EmailVerificationBox/EmailVerificationBox';
import AddressSelector from '../../components/AddressSelector/AddressSelector';
import OrderButton from '../../components/OrderButton/OrderButton';
import { IAddress } from '@snoam/mono-address';
import { emailValidator } from '../../components/InputWithValidator/InputWithValidator';
import { useQuery } from '@apollo/client';
import { LoadingFragment } from '../LoadingPage';
import { EmptyAgreements } from '../Agreements/EmptyAgreements';
import { withSpidContext } from '@snoam/mono-spid';
import { MySpidContext, requestInviteCode } from '../../utils';
import { OrderSessionModel, Product, Reference } from "./models/OrderSessionModel";
import { GET_AGREEMENT_ORDER_INITIAL } from "../../queries";
import {
  GetAgreementOrderInitial,
  GetAgreementOrderInitial_agreement_departments,
  GetAgreementOrderInitial_agreement_products,
  GetAgreementOrderInitial_agreement_settings,
  GetAgreementOrderInitialVariables
} from "../../__generated__/GetAgreementOrderInitial";
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';
import { MinBedriftRouteComponentProps } from "../../routes/utils";
import { EmploymentVerificationMethod } from "../../__generated__/globalTypes";
import { ProductType } from "../Subscriptions/ProductTypeFilter";


const styleClass = {
  container: classNames(
    Padding.PB_10,
    Padding.PX_4,
    Margin.MB_8,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_2XL,
    Margin.MB_4,
    TextAlign.TEXT_CENTER,
  ),
};

export const MissingAddressErrorBox: React.FunctionComponent = () => {
  return (
    <Card transparent={TransparentSkin.NEGATIVE_20} arrow={Arrow.TOP_CENTER} className={Margin.MT_3}>
      <div className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER)}>
        <Icon name={IconName.INFO} color={IconColor.NEGATIVE} className={Margin.MR_2}/>
        <div className={classNames(TextColor.TEXT_NEUTRAL_7)}>Vennligst velg adresse</div>
      </div>
    </Card>
  );
};

export interface UserInformationPageProps extends MinBedriftRouteComponentProps<{ agreementNumber: string }> {
  orderModel?: OrderSessionModel;
  referencesPrProduct?: boolean;
  spidContext: MySpidContext;
}

export const  UserInformationPage: React.FunctionComponent<UserInformationPageProps> = ({history, location, match, orderModel: inputOrderModel, referencesPrProduct: referencesPrProductInput, spidContext}) => {
  const agreementNumber = +match.params.agreementNumber;
  const variables = {agreementNumber: agreementNumber};
  const orderModel = inputOrderModel || new OrderSessionModel();

  const {data, error, loading} = useQuery<GetAgreementOrderInitial, GetAgreementOrderInitialVariables>(GET_AGREEMENT_ORDER_INITIAL, {
    variables,
    fetchPolicy: "cache-first"
  });

  const defaultAddress = {streetNumber: {}, street: {}};

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [address, setAddress] = useState<any>(orderModel.address || defaultAddress);
  const [email, setEmail] = useState(orderModel.email || '');
  const [referencesPrProduct, setReferencesPrProduct] = useState<boolean>(typeof referencesPrProductInput !== 'undefined' ? referencesPrProductInput : false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [references, setReferences] = useState<Partial<Reference>[]>(orderModel.references || []);
  const [scoreError, setScoreError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [departmentValid, setDepartmentValid] = useState<boolean>(false);

  const departments = (): GetAgreementOrderInitial_agreement_departments[] => {
    return data && data.agreement && data.agreement.departments || [];
  }

  useEffect(() => {
    orderModel.email = email;
    orderModel.address = address;
  }, [email, address]);
  useEffect(() => {
    setDepartmentValid((departments() && departments().length === 0) || orderModel.products.every((p) => p.department));
  }, [selectedDepartments.length]);

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error || !data || !data.agreement) {
    return <EmptyAgreements error={error}/>;
  }
  const agreement = data.agreement;
  const settings: GetAgreementOrderInitial_agreement_settings = agreement.settings;
  const selectedProducts: GetAgreementOrderInitial_agreement_products[] = agreement
    .products
    .filter(p => orderModel.getProductById(p.id));
  const numberOfRefs = settings.reference1 && settings.reference2 ? 2 * selectedProducts.length : settings.reference1 ? selectedProducts.length : 0;
  const useEmailVerification = settings.employeeVerificationMethod === EmploymentVerificationMethod.EMAIL;

  const getProducts: () => { [k: string]: {
      companyCode: string;
      productCombination: string;
      productName: string;
    } } = () => selectedProducts.reduce((o, p) => {
    return {
      ...o,
      [p.siebelProductId]: {
        companyCode: p.companyCode,
        productCombination: p.productCombination,
        productName: p.productName,
      },
    };
  }, {});

  const getReferences = ()=> selectedProducts.reduce((o, p) => {
    const product = orderModel.getProductById(p.id) as Product;
    return {
      ...o,
      [p.siebelProductId]: {
        reference1: product.ref1 || '',
        reference2: product.ref2 || '',
        billingAgreementNumber: product.department
      },
    };
  }, {});

  const showAddress = !!selectedProducts.find((product) => ProductType.Papir.test(product.productCombination));
  const addressValid = showAddress ? address && address.streetNumber && address.streetNumber.deliveryPointId : true;
  const emailValid = !useEmailVerification || emailValidator(settings.emailDomains)(email);
  const referencesValid = orderModel.references.length === numberOfRefs;
  const canProceed = (addressValid && departmentValid && emailValid && referencesValid && !submitting) || false;

  const scoreErrorMessage = scoreError && (
    <Grid gutter={GridGutter.NONE}>
      <GridItem className={classNames(Padding.PT_3, JustifyContent.JUSTIFY_CENTER)}>
        <MessageBox type={MessageBoxType.ERROR} arrow={Arrow.TOP_CENTER} message={scoreError}/>
      </GridItem>
    </Grid>
  );

  const redirectPath = (window.location.pathname).replace(/\/brukerinformasjon/, '/til-ekstern-login');
  const onProceedClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    if(!canProceed) {
      return;
    }

    setFormSubmitted(true);
    setScoreError('');
    setSubmitting(true);
    if (canProceed) {
      if (useEmailVerification) {
        requestInviteCode({
          address: orderModel.address as IAddress,
          agreementNumber,
          email,
          products: getProducts(),
          references: getReferences(),
          selectedProducts,
          spidContext
        }).then((redirectPath) => {
          history.push(redirectPath);
        }).catch((err) => {
          setScoreError(err.errorMessage || "Noe gikk galt. Prøv igjen senere.")
        }).finally(() => {
          setSubmitting(false);
        });
      } else {
        history.push(redirectPath);
      }
    }
  };
  const autoProceed = !showAddress && !useEmailVerification && numberOfRefs === 0 && departmentValid && canProceed;
  if (autoProceed) {
    history.replace(redirectPath);
  }

  return (
    <Container width={ContainerWidth.NARROW} className={styleClass.container} style={{maxWidth: '550px'}}>
      <Heading level={HeadingLevel.ONE} className={styleClass.heading}>
        Legg inn din informasjon
      </Heading>

      {(departments().length > 0 || settings.reference1) &&
      <ReferencesPerProductBox
        orderModel={orderModel}
        setSelectedDepartments={setSelectedDepartments}
        referencesPrProduct={referencesPrProduct}
        setReferencesPrProduct={setReferencesPrProduct}
        setReferences={setReferences}
        formSubmitted={formSubmitted}
        history={history}
        location={location}
        departments={departments()}
        products={selectedProducts}
        settings={settings}/>
      }

      { useEmailVerification ? <EmailVerificationBox
          email={ email }
          onChange={ (s) => setEmail(s) }
          formSubmitted={ formSubmitted }
          className={ Margin.MT_4 }
          agreement={ agreement }
          settings={ settings }
      /> : null }

      {scoreErrorMessage}

      {showAddress && <AddressSelector address={address} addressSelected={(address) => setAddress(address)}/>}

      {showAddress && formSubmitted && !addressValid && <MissingAddressErrorBox/>}

      <OrderButton
        canProceed={canProceed}
        onProceedClick={onProceedClick}
        text={'Gå videre'}
        backBtnUrl={`/bestill/${agreement.agreementNumber}/flyt/produkter${window.location.search}`}
      />
    </Container>
  );
};

export default withSpidContext(UserInformationPage);
