import Page from "../../components/Page/Page";
import LoginPage from "../../pages/LoginPage/LoginPage";
import FilterProvider from "../../context/FilterContext/FilterContext";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import * as React from "react";
import {EnvelopeProvider} from "../../context/EnvelopeContext/EnvelopeContext";
import {DesktopSideBarWidth, FilterEnvelopeWidth} from "../../Const";
import {BackgroundColor, ContainerWidth} from '@snoam/pinata';
import {ModalProvider} from "@snoam/mono-modal"
import DegreeNavigation from "../../components/DegreeNavigation/DegreeNavigation";
import {useContext} from "react";
import {BackgroundContext} from "@snoam/mono-background-loader";
import {DepartmentContextProvider} from "../../context/DepartmentContext/DepartmentContext";
import {Tracker} from "./Tracker";
import OrderPage from "../../pages/Order/OrderPage";
import {MessageContextProvider} from "../../context/MessageContext/MessageContext";

const EnvelopeProviderWrapper: React.FunctionComponent = ({children}) => (
  <EnvelopeProvider
    width={FilterEnvelopeWidth}
  >
    {children}
  </EnvelopeProvider>
);

const PageWithBackgroundColorAndNoFooter: React.FunctionComponent = ({children}) => (
  <Page hideFooter={true} className={BackgroundColor.BG_NEUTRAL_2}>
    {children}
  </Page>
);

const OrderPageWithBackgroundColorAndNoFooter: React.FunctionComponent = ({children}) => (
  <OrderPage hideFooter={true} className={BackgroundColor.BG_NEUTRAL_2}>
    {children}
  </OrderPage>
);

const PageWithNoFooter: React.FunctionComponent = ({children}) => (
  <Page hideFooter={true} className={BackgroundColor.BG_TRANSPARENT}>
    {children}
  </Page>
);

export const AdminNavigation: React.FunctionComponent<{}> = ({children}) => {
  const {setBgImage, setStyle} = useContext(BackgroundContext);
  React.useEffect(()=>{
    setBgImage(`${process.env.PUBLIC_URL}/img/navbar_background_img.png`);
    setStyle({backgroundSize: DesktopSideBarWidth});
  }, [children]);
  return (
    <DegreeNavigation
      key={'admin-navigation'}
      hideSidebar={false}
      wrapperClassName={BackgroundColor.BG_NEUTRAL_2}>
      {children}
    </DegreeNavigation>
  );
};

export const GenericNavigation: React.FunctionComponent<{}> = ({children}) => {
  const {setBgImage, setStyle} = useContext(BackgroundContext);
  React.useEffect(()=>{
    setBgImage(`${process.env.PUBLIC_URL}/img/navbar_background_img.png`);
    setStyle({backgroundSize: DesktopSideBarWidth});
  }, []);
  return (
    <DegreeNavigation
      key={'generic-navigation'}
      hideSidebar={false}
      wrapperClassName={BackgroundColor.BG_NEUTRAL_2}>
      {children}
    </DegreeNavigation>
  )
};

export const LoginNavigation: React.FunctionComponent<{}> = ({children}) => {
  const {setBgImage, setStyle} = useContext(BackgroundContext);
  React.useEffect(()=>{
    setBgImage(`${process.env.PUBLIC_URL}/img/menu_bg_mobile.png`);
    setStyle({backgroundSize: "cover"});
  }, []);
  return (
    <DegreeNavigation
      key={'login-navigation'}
      hideSidebar={true}
    >
      {children}
    </DegreeNavigation>
  )
};

export const OrderNavigation: React.FunctionComponent<{}> = ({children}) => {
  const {setBgImage, setStyle} = useContext(BackgroundContext);
  React.useEffect(()=>{
    setBgImage(`${process.env.PUBLIC_URL}/img/menu_bg_mobile.png`);
    setStyle({backgroundSize: "cover"});
  }, [children]);
  return (
    <DegreeNavigation
      key={'order-navigation'}
      hideSidebar={true}
    >
      {children}
    </DegreeNavigation>
  )
};

export const SubscriptionComposition: React.ComponentType<any>[] = [
  Page,
  ModalProvider,
  EnvelopeProviderWrapper,
  FilterProvider,
  MessageContextProvider,
  DepartmentContextProvider,
  Tracker,
  ErrorBoundary,
];


export const FilterComposition: React.ComponentType[] = [
  Page,
  ModalProvider,
  EnvelopeProviderWrapper,
  FilterProvider,
  ErrorBoundary,
];

export const PageComposition: React.ComponentType[] = [
  Page,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];

export const OrderLoginPageComposition: React.ComponentType[] = [
  PageWithNoFooter,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];

export const OrderPageWithBackgroundComposition: React.ComponentType[] = [
  PageWithBackgroundColorAndNoFooter,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];

export const OrderPageFlowWithBackgroundComposition: React.ComponentType[] = [
  OrderPageWithBackgroundColorAndNoFooter,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];


const LoginPageWithFooter: React.FunctionComponent = ({children}) => (
  <LoginPage width={ContainerWidth.DEFAULT} className={BackgroundColor.BG_TERTIARY_3}>
    {children}
  </LoginPage>
);

export const LoginPageComposition: React.ComponentType[] = [
  LoginPageWithFooter,
  Tracker,
  ErrorBoundary
];
